import { createSlice } from "@reduxjs/toolkit";
import { TESTROOMS } from "../mockup/test-data";

const slice = createSlice({
  name: "customer",
  initialState: {
    customerData: {},
    oldCustomerData: {},
    selectedMode: "Images",
    selectedRoom: null,
    selectedChecklist: null,
    testRooms: TESTROOMS,
    activeAccordions: [],
    uploading: false,
    isLoading: false,
  },
  reducers: {
    setCustomerData: (state, action) => {
      state.customerData = action.payload;
    },
    setOldCustomerData: (state, action) => {
      state.oldCustomerData = action.payload;
    },
    setMode: (state, action) => {
      state.selectedMode = action.payload;
    },
    setRoom: (state, action) => {
      state.selectedChecklist = null;
      state.selectedRoom = action.payload;
    },
    setChecklist: (state, action) => {
      state.selectedRoom = null;
      state.selectedChecklist = action.payload;
    },
    updateRooms: (state, action) => {
      state.testRooms = action.payload;
    },
    updateActiveAccordions: (state, action) => {
      state.activeAccordions = action.payload;
    },
    setUploading: (state, action) => {
      state.uploading = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setMode,
  setRoom,
  setChecklist,
  updateRooms,
  setCustomerData,
  setOldCustomerData,
  updateActiveAccordions,
  setUploading,
  setIsLoading,
} = slice.actions;

export const toggleAccordion = (title) => async (dispatch, getState) => {
  let activeAccordions = getState()?.customerReducer?.activeAccordions;

  if (activeAccordions.includes(title)) {
    const updatedAcc = activeAccordions.filter((t) => t !== title);
    dispatch(updateActiveAccordions([...updatedAcc]));
  } else {
    dispatch(updateActiveAccordions([...activeAccordions, title]));
  }
};

export default slice.reducer;
