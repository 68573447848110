import {
  Backdrop,
  CircularProgress,
  Fab,
  Grid,
  LinearProgress,
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import SingleImage from "../../components/ui/single-image";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerData } from "../../redux/customer";
import { handleImageCompression } from "../../helper";
import {
  ImageObjectToZoho,
  uploadImage,
} from "../../services/customer.service";
import { useSearchParams } from "react-router-dom";
import { Constant } from "../../utils/constants";
import NoContentContainer from "../../components/ui/no-content";
import BackdropLinearProgress from "../../components/ui/backdrop-linear-progress";

const ChecklistImages = (props) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queryValue = searchParams.get("id");
  const { clImages, otherClImages } = props;
  const fieldVisitImages = useSelector(
    (state) => state.customerReducer.customerData.Field_Visit_Images
  );
  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );
  const selectedChecklist = useSelector(
    (state) => state.customerReducer.selectedChecklist
  );

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return; // Handle no file selected

    handleImageUpload(selectedFile);
  };

  const handleImageUpload = async (imageFile) => {
    if (!imageFile) return; // Prevent upload if no image selected
    setUploading(true);

    const compressedFile = await handleImageCompression(imageFile);

    let formData = new FormData();
    formData.append("file", compressedFile);
    formData.append("originalname", imageFile?.name);

    let imageUploaded = await uploadImage(formData, setProgress);
    if (imageUploaded.data.status) {
      if (selectedChecklist) {
        const extractSubfloorDetail = (fullName) => {
          const parts = fullName.split("-").map((part) => part.trim());
          if (parts.length > 1) {
            return parts.slice(1).join(" - ");
          }
          return "";
        };
        const extractedDetail = extractSubfloorDetail(selectedChecklist?.Name);

        const Potential = {
          id: customerData?.potential?.id || "",
          name: customerData?.potential?.Deal_Name || "",
        };
        const FV_Projects = {
          id: customerData?.fv_project?.id || "",
          name: customerData?.fv_project?.Name || "",
        };
        let newString =
          extractedDetail?.includes("Matching Installation") ||
          extractedDetail?.includes("Refinishing Checklist") ||
          extractedDetail?.includes("Subfloor Detail")
            ? extractedDetail.replace("- ", "")
            : extractedDetail;
        const totalSameCkListImage = fieldVisitImages?.filter((cklist) =>
          cklist?.Name?.includes(newString)
        );
        const Related_To_Form_Name = extractedDetail?.includes(
          "Refinishing Checklist"
        )
          ? "Refinishing Checklist"
          : extractedDetail?.includes("Existing Material")
          ? "Existing Material"
          : extractedDetail?.includes("Subfloor Detail")
          ? "Subfloor Detail"
          : extractedDetail?.includes("Molding")
          ? "Molding"
          : extractedDetail?.includes("Matching Refinishing")
          ? "Matching Refinishing"
          : extractedDetail?.includes("Matching Installation")
          ? "Matching Installation"
          : extractedDetail?.includes("Leveling")
          ? "Leveling"
          : extractedDetail?.includes("Transition")
          ? "Transition"
          : extractedDetail?.includes("Staircase")
          ? "Staircase"
          : extractedDetail?.includes("Repair")
          ? "Repair"
          : "";
        const newCheckListImge = {
          Field_Visit_Room: null,
          Field_Name:
            extractedDetail?.includes("Transition") ||
            extractedDetail?.includes("Staircase") ||
            extractedDetail?.includes("Repair") ||
            extractedDetail?.includes("Matching Refinishing") ||
            extractedDetail?.includes("Molding") ||
            extractedDetail?.includes("Leveling")
              ? extractedDetail
              : null,
          Name: `${newString} # Image-${totalSameCkListImage?.length + 1}`,
          Related_To_Form: Related_To_Form_Name || "",
          Sub_Field_Name: null,
          URL: imageUploaded?.data?.entity,
          Potential: Potential,
          Field_Visit_Project: FV_Projects,
        };
        let response = await ImageObjectToZoho(queryValue, {
          module_name: "Field_Visit_Images",
          record_obj: newCheckListImge,
        });
        console.log("response", response);

        let fvImages = [
          ...fieldVisitImages,
          response?.data?.entity?.details?.output?.data,
        ];
        const updatedCustomerData = {
          ...customerData,
          Field_Visit_Images: fvImages,
        };

        // dispatch(setCustomerData(updatedCustomerData));
        dispatch(setCustomerData(updatedCustomerData));
      }
    } else {
      console.log("ERROR", imageUploaded?.data?.message);
    }
    setUploading(false);
    setProgress(0);
  };

  const handleFabClick = () => {
    fileInputRef.current.click();
  };

  const imagesToRender = useMemo(() => {
    if (clImages?.length > 0) return clImages;
    if (otherClImages?.length > 0) return otherClImages;
    return [];
  }, [clImages, otherClImages, fieldVisitImages]);

  return (
    <>
      <div className="flex justify-end items-center mb-2">
        <input
          style={{ display: "none" }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
        />
        <Fab
          color="secondary"
          sx={{ borderRadius: "8px" }}
          className={`${Constant.BG_PRIMARY}`}
          size="small"
          component="span"
          aria-label="add"
          variant="extended"
          onClick={handleFabClick}
        >
          + Add Image
        </Fab>
      </div>
      {imagesToRender?.length > 0 ? (
        <Grid container spacing={2}>
          {imagesToRender.map((img, idx) => (
            <SingleImage
              key={img.URL}
              imgUrl={img.URL}
              id={img?.id}
              index={idx}
              roomImages={imagesToRender}
            />
          ))}
        </Grid>
      ) : (
        <NoContentContainer>NO IMAGES</NoContentContainer>
      )}

      {uploading && <BackdropLinearProgress />}
    </>
  );
};

export default ChecklistImages;
