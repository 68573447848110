export const MATCHING_REF = [
  [
    {
      question: "How is the space connected to the area being refinished?",
      key: "How_space_connected_to_the_area_being_refinhed",
      type: "DROPDOWN",
      option: [
        "Adjacent to Space",
        "Near Space",
        "Different Levels",
        "Different Areas of the Home",
        "Matching Existing Floor Colors",
      ],
    },
    {
      question: "Matching Refinishing Notes",
      key: "Matching_Refinishing_Notes",
      type: "TEXT",
    },
  ],
];
