import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import CustomAccordion from "../../../components/accordion";
import { getChecklistJunction } from "../../../utils/common-utils";
import _ from "lodash";
import Floors from "../../../components/accordion/floors";

const ChecklistForm = React.memo(
  ({
    selectedChecklist,
    renderQuestions,
    checklistItems,
    isScope,
    clKey,
    updateChecklistInRoom,
    floorWiseRooms,
    poId,
    fetchCustomerData,
  }) => {
    const customerData = useSelector(
      (state) => state.customerReducer.customerData
    );

    const selectedMode = useSelector(
      (state) => state.customerReducer.selectedMode
    );

    const roomsWithinSelectedChecklist = useMemo(() => {
      const [checklistJunctionKey, junctionInsiderKeyToCheck] =
        getChecklistJunction(clKey, "junction");

      /**
       * Filter from current checklist's junction module (i.e Rooms_x_Refinishing) to get the rooms, in which selected checklist is being applied
       */
      const currentChecklistRooms = _.map(
        customerData[checklistJunctionKey],
        (clJunction) => {
          const clColor =
            customerData[clKey]?.find(
              (cl) => cl?.id === clJunction[junctionInsiderKeyToCheck]?.id
            )?.Color || "";

          return { ...clJunction?.Field_Visit_Room, bgColor: clColor };
        }
      );

      return currentChecklistRooms;
    }, [customerData, clKey, selectedChecklist]);

    const getAccordionChildren = React.useCallback(
      (questions) => {
        if (!questions || questions?.length === 0) return <></>;

        return (
          <Grid container spacing={2}>
            {questions.map((question, idx) => {
              return (
                <Grid
                  key={`${idx}+${question.key}`}
                  item
                  xs={idx === 0 ? 12 : 3}
                >
                  {renderQuestions(question)}
                </Grid>
              );
            })}
          </Grid>
        );
      },
      [selectedChecklist, customerData]
    );

    const getSpacesAccordionChildren = React.useCallback(() => {
      if (!customerData?.FV_Rooms || customerData?.FV_Rooms?.length === 0)
        return <></>;

      return (
        <Floors
          customerData={customerData}
          roomsWithinSelectedChecklist={roomsWithinSelectedChecklist}
          updateChecklistInRoom={updateChecklistInRoom}
          selectedChecklist={selectedChecklist}
          floorWiseRooms={floorWiseRooms}
          clKey={clKey}
          poId={poId}
          fetchCustomerData={fetchCustomerData}
        />
      );
    }, [
      selectedChecklist,
      roomsWithinSelectedChecklist,
      customerData,
      floorWiseRooms,
      clKey,
      updateChecklistInRoom,
    ]);

    const getAccordionTitle = React.useCallback(
      (question) => {
        let title = question;
        if (isScope && question === "Repair") {
          title = "Other";
        }
        return title;
      },
      [selectedChecklist, customerData, isScope]
    );

    return (
      <div className="space-y-2">
        {selectedMode === "Checklists" && (
          <CustomAccordion
            accordionTitle="Spaces"
            accordionChildren={getSpacesAccordionChildren()}
          />
        )}
        {checklistItems?.map((mainQueArray, mainIndex) => {
          let title = getAccordionTitle(mainQueArray[0].question);

          return (
            <CustomAccordion
              key={mainIndex + Math.random()}
              accordionTitle={title}
              accordionChildren={getAccordionChildren(mainQueArray)}
            />
          );
        })}
      </div>
    );
  }
);

export default ChecklistForm;
