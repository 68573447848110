import {
  setChecklist,
  setCustomerData,
  setOldCustomerData,
} from "../redux/customer";
import {
  deleteRecordFromZoho,
  updateFVRecordInZoho,
} from "../services/customer.service";
import { CreateRecord } from "./create-record";

export class ChecklistApiUtil {
  constructor(module_name) {
    this.module_name = module_name;
  }

  async deleteRecord(recordId, alertRef, fetchCustomerData) {
    const response = await deleteRecordFromZoho({
      module_name: this.module_name,
      record_id: recordId,
    });

    if (response.data?.entity?.code === "success") {
      // await fetchCustomerData();
      alertRef?.openSnackbar(true, "Record deleted!", "success");
    } else {
      alertRef?.openSnackbar(
        true,
        "Something went wrong, record didn't deleted",
        "error"
      );
    }
  }

  async addNewRecord(poId, recordObj, alertRef, fetchCustomerData) {
    const response = await updateFVRecordInZoho(poId, {
      module_name: this.module_name,
      record_obj: recordObj,
    });

    if (response.data?.entity?.code === "success") {
      alertRef?.openSnackbar(true, "Room updated with checklist!", "success");
      return response?.data?.entity?.details?.output?.data;
      // await fetchCustomerData();
    } else {
      alertRef?.openSnackbar(
        true,
        response.data?.message || "Something went wrong, record didn't deleted",
        "error"
      );
      return null;
    }
  }

  async bulkRemoveRecord(records, alertRef) {
    if (records?.length === 0) return;

    const deleteRecordsResponse = await Promise.all(
      records?.map((record) => {
        if (record?.id) {
          return deleteRecordFromZoho({
            module_name: this.module_name,
            record_id: record.id,
          });
        }
      })
    );

    const notDeletedRecords = [];
    deleteRecordsResponse?.map((res, idx) => {
      if (res?.data?.entity?.code !== "success") {
        notDeletedRecords.push(records[idx]?.Name);
      }
    });

    if (notDeletedRecords.length === 0) {
      // await fetchCustomerData();
      // alertRef?.openSnackbar(true, "Checklist deleted!", "success");
    } else {
      alertRef?.openSnackbar(
        true,
        `Something went wrong, record of ${notDeletedRecords.join(
          ", "
        )} didn't updated`,
        "error"
      );
    }
  }

  static updateCustomerDataWithJunctionModule(
    customerData,
    checklistJunctionKey,
    newChecklistJunction,
    dispatch
  ) {
    let clonedOldCustomerData = JSON.parse(JSON.stringify(customerData));

    let existinJunctionIndex = clonedOldCustomerData[
      checklistJunctionKey
    ]?.findIndex((junction) => junction.id === newChecklistJunction.id);

    if (existinJunctionIndex !== -1) {
      clonedOldCustomerData[checklistJunctionKey][existinJunctionIndex] =
        newChecklistJunction;
    } else {
      clonedOldCustomerData[checklistJunctionKey] = [
        ...clonedOldCustomerData[checklistJunctionKey],
        newChecklistJunction,
      ];
    }

    dispatch(setCustomerData(clonedOldCustomerData));
    dispatch(setOldCustomerData(clonedOldCustomerData));
  }

  static deleteJunctionModuleFromCustomerData(
    customerData,
    checklistJunctionKey,
    deleteRecordId,
    dispatch
  ) {
    let clonedOldCustomerData = JSON.parse(JSON.stringify(customerData));

    let existinJunctionIndex = clonedOldCustomerData[
      checklistJunctionKey
    ]?.findIndex((junction) => junction.id === deleteRecordId);

    if (existinJunctionIndex !== -1) {
      clonedOldCustomerData[checklistJunctionKey].splice(
        existinJunctionIndex,
        1
      );
    }

    dispatch(setCustomerData(clonedOldCustomerData));
    dispatch(setOldCustomerData(clonedOldCustomerData));
  }

  static updateMainRecord(
    customerData,
    selectedChecklist,
    clKey,
    updatedCL,
    dispatch
  ) {
    let clonedCustomerData = JSON.parse(JSON.stringify(customerData));

    clonedCustomerData[clKey] = clonedCustomerData[clKey].map((item) => {
      if (item.id === selectedChecklist.id) {
        return updatedCL;
      }
      return item;
    });

    dispatch(setChecklist(updatedCL));
    dispatch(setCustomerData(clonedCustomerData));
    dispatch(setOldCustomerData(clonedCustomerData));
  }

  static batchDeleteForModulesInCustomerData(
    customerData,
    keyToCheck,
    records
  ) {
    let clonedOldCustomerData = JSON.parse(JSON.stringify(customerData));

    records?.forEach((record) => {
      let existinJunctionIndex = clonedOldCustomerData[keyToCheck]?.findIndex(
        (junction) => junction.id === record.id
      );

      if (existinJunctionIndex !== -1) {
        clonedOldCustomerData[keyToCheck].splice(existinJunctionIndex, 1);
      }
    });

    return clonedOldCustomerData;
  }
}
