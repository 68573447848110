import { setIsLoading } from "../redux/customer";
import { ChecklistApiUtil } from "./checklist-api-util";

export class DropUtil {
  static async updateStaircaseData(
    clKey,
    item,
    customerData,
    selectedChecklist,
    dispatch,
    poId,
    buttonName,
    ref
  ) {
    const updateRecordInstance = new ChecklistApiUtil(clKey);
    let curCL = { ...selectedChecklist };
    const floorId = customerData.FV_Floors.find(
      (floor) => floor.Name === buttonName
    )?.id;

    if (item.type === "From") {
      if (curCL.From_Floor && curCL.From_Floor.id === floorId) return;

      dispatch(setIsLoading(true));

      curCL.From_Floor = {
        name: buttonName,
        id: floorId,
      };

      /**
       * check if current staircase has same floorId in To_Floor, then it is going to be null
       */
      if (curCL.To_Floor && curCL.To_Floor.id === floorId) {
        curCL.To_Floor = null;
      }
    } else if (item.type === "To") {
      if (curCL.To_Floor && curCL.To_Floor.id === floorId) return;

      dispatch(setIsLoading(true));

      curCL.To_Floor = {
        name: buttonName,
        id: floorId,
      };

      /**
       * check if current staircase has same floorId in From_Floor, then it is going to be null
       */
      if (curCL.From_Floor && curCL.From_Floor.id === floorId) {
        curCL.From_Floor = null;
      }
    }

    const updatedCL = await updateRecordInstance.addNewRecord(
      poId,
      curCL,
      ref.current
    );

    ChecklistApiUtil.updateMainRecord(
      customerData,
      selectedChecklist,
      clKey,
      updatedCL,
      dispatch
    );
    dispatch(setIsLoading(false));
  }

  static async updateTransitionData(
    clKey,
    item,
    customerData,
    selectedChecklist,
    dispatch,
    poId,
    room,
    ref
  ) {
    const updateRecordInstance = new ChecklistApiUtil(clKey);
    let curCL = { ...selectedChecklist };
    const roomId = room.id;
    const roomName = room.Name;
    let shouldApiCall = false;

    if (item.type === "From") {
      if (curCL.From_Room && curCL.From_Room.id === roomId) return;

      dispatch(setIsLoading(true));

      curCL.From_Room = {
        name: roomName,
        id: roomId,
      };
      curCL.Within_Room = null;

      /**
       * check if current transition has same roomId in To_Room, then it is going to be null
       */
      if (curCL.To_Room && curCL.To_Room.id === roomId) {
        curCL.To_Room = null;
      }

      /**
       * if To_Room is there wihtout being null in above condition, then and then the api should be called
       */
      if (curCL.To_Room) {
        shouldApiCall = true;
      }
    } else if (item.type === "To") {
      if (curCL.To_Room && curCL.To_Room.id === roomId) return;

      dispatch(setIsLoading(true));

      curCL.To_Room = {
        name: roomName,
        id: roomId,
      };
      curCL.Within_Room = null;

      /**
       * check if current transition has same roomId in From_room, then it is going to be null
       */
      if (curCL.From_Room && curCL.From_Room.id === roomId) {
        curCL.From_Room = null;
      }

      /**
       * if From_Room is there wihtout being null in above condition, then and then the api should be called
       */
      if (curCL.From_Room) {
        shouldApiCall = true;
      }
    } else if (item.type === "Within") {
      if (curCL.Within_Room && curCL.Within_Room.id === roomId) return;

      shouldApiCall = true;
      dispatch(setIsLoading(true));

      curCL.Within_Room = {
        name: roomName,
        id: roomId,
      };

      /**
       * If transition is being set to Within_room, then From_room and To_room will be set to null
       */
      curCL.From_Room = null;
      curCL.To_Room = null;
    }

    let updatedCL = curCL;
    if (shouldApiCall) {
      updatedCL = await updateRecordInstance.addNewRecord(
        poId,
        curCL,
        ref.current
      );
    }

    ChecklistApiUtil.updateMainRecord(
      customerData,
      selectedChecklist,
      clKey,
      updatedCL,
      dispatch
    );
    dispatch(setIsLoading(false));
  }
}
