import _ from "lodash";

export class FloorUtil {
  constructor(customerData) {
    this.floors = customerData?.FV_Floors;
    this.rooms = customerData?.FV_Rooms;
  }

  getFloorWiseRooms() {
    let floorWithRooms = this.floors?.reduce((acc, floor) => {
      const rooms = this.rooms?.filter(
        (room) => room?.Field_Visit_Floor?.id === floor?.id
      );

      acc[floor.Name] = rooms;

      return acc;
    }, {});

    // sort the floors from basement to floor 1,2,3
    floorWithRooms = _.fromPairs(_.sortBy(_.toPairs(floorWithRooms), 0));

    return floorWithRooms;
  }

  getFloorWiseRoomsWithSubRooms() {
    let floorWithRooms = _.chain(this.rooms)
      .groupBy("Field_Visit_Floor.name")
      .map((rooms, key) => {
        let mainRooms = _.filter(rooms, (r) => !r?.Sub_Room_Of);
        mainRooms = mainRooms.map((mainRoom) => {
          const clonedMainroom = _.cloneDeep(mainRoom);
          let subRooms = _.filter(
            this.rooms,
            (r) => r?.Sub_Room_Of?.id === mainRoom?.id
          );
          clonedMainroom.subRooms = subRooms;
          return clonedMainroom;
        });
        return { floorName: key, rooms: mainRooms };
      })
      .value();

    // sort the floors from basement to floor 1,2,3
    floorWithRooms = _.sortBy(floorWithRooms, (floor) => floor.floorName);

    return floorWithRooms;
  }

  getRoomIdsFromFloor(floorName) {
    const roomIds = _.chain(this.rooms)
      .filter((r) => r?.Field_Visit_Floor?.name === floorName)
      .map((room) => {
        return room?.id;
      })
      .value();

    return roomIds;
  }
}
