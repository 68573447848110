import { Suspense } from "react";
import { LinearProgress } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AppRoutes from "./routes/app-routes";
import "./App.css";

function App() {
  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={<LinearProgress />}>
          <AppRoutes />
        </Suspense>
      </DndProvider>
    </div>
  );
}

export default App;
