const APIURL = process.env.REACT_APP_API_URL;
// const APIURL = 'http://proposal-backend.kasawalkthrough.com/api'

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${APIURL}${url}`;

  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

export const GET_REFINEMENT_DATA_BY_ID = (id) =>
  UrlParamsReplace("/get-refinement-data/:id", { id });

export const GET_FILE_FROM_URL = () => UrlParamsReplace("/get-file-data", {});

// Push fv data to zoho api
export const PUSH_STAIRCASEDATA_TO_ZOHO = () =>
  UrlParamsReplace(`/staircase-push-to-zoho`);
export const PUSH_PROPOSALDATA_TO_ZOHO = () =>
  UrlParamsReplace(`/proposal-push-to-zoho`);
export const PUSH_PRODUCTS_TO_ZOHO = () =>
  UrlParamsReplace(`/products-push-to-zoho`);
export const PUSH_TRANSITION_TO_ZOHO = () =>
  UrlParamsReplace(`/transition-push-to-zoho`);
// export const GET_USER_BY_ID = (id) => UrlParamsReplace('/auth/get-user/:id', { id })
export const GET_USER_BY_ID = (id) => {
  return `https://fvbackend.kasawalkthrough.com/api/auth/get-user/${id}`;
};

export const UPLOAD_IMAGE = (customerId) =>
  "https://fvbackend.kasawalkthrough.com/api/upload-file";

export const PUSH_IMAGES_TO_ZOHO = (id) =>
  UrlParamsReplace(`/update-fv-record-in-zoho/${id}`);

export const UPDATE_FV_RECORD_IN_ZOHO = (id) =>
  UrlParamsReplace(`/update-fv-record-in-zoho/${id}`);

export const DELETE_IMAGES_IN_ZOHO = (id) =>
  UrlParamsReplace(`/delete-image-in-zoho`);

export const DELETE_RECORD_FROM_ZOHO = (id) =>
  UrlParamsReplace(`/delete-image-in-zoho`);
