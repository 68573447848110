import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { toggleAccordion } from "../../redux/customer";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "1px solid #80808047",
  borderRadius: "10px",
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(145, 158, 171, 0.12)",
  borderRadius: "10px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  textAlign: "start",
  fontSize: "14px",
}));

const CustomAccordion = (props) => {
  const { accordionTitle, accordionChildren } = props;
  const activeAccordions = useSelector(
    (state) => state.customerReducer.activeAccordions
  );
  const dispatch = useDispatch();

  const handleChange = () => {
    dispatch(toggleAccordion(accordionTitle));
  };

  return (
    <>
      <Accordion
        expanded={activeAccordions.includes(accordionTitle)}
        onChange={handleChange}
      >
        <AccordionSummary aria-controls="panel-content" id="panel-header">
          <Typography sx={{ fontSize: "14px" }}>{accordionTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="space-y-2">{accordionChildren}</div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CustomAccordion;
