import React from "react";
import { useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../../utils/use-auth";

const WithAuth = ({ children }) => {
  const [searchParams] = useSearchParams();
  const queryValue = searchParams.get("id");
  const userId = searchParams.get("userId");

  const { loggedIn } = useAuth({ queryValue, userId });

  return (
    <>
      {loggedIn ? (
        children
      ) : (
        <div className="fixed left-[50%] top-[50%] z-[1000]">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default WithAuth;
