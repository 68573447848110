import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RemoveButton from "../ui/remove-button";
import DroppableMotionButton from "../../layout/sidebar/DroppableMotionButton";
import ChecklistNameWithColor from "../ui/checklist-with-color";
import DeleteConfirmation from "../modal/delete-confirmation";
import PopUpModal from "../modal/popup-modal";
import {
  getChecklistJunction,
  getChecklistToChange,
  getImages,
} from "../../utils/common-utils";
import { ChecklistApiUtil } from "../../utils/checklist-api-util";
import {
  setChecklist,
  setCustomerData,
  setIsLoading,
  setOldCustomerData,
} from "../../redux/customer";
import MuiSnackbar from "../ui/mui-snackbar";

const ChecklistAccordionChild = (props) => {
  const { checklist, changeContext, idx } = props;

  const selectedMode = useSelector(
    (state) => state.customerReducer.selectedMode
  );
  const selectedChecklist = useSelector(
    (state) => state.customerReducer.selectedChecklist
  );
  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const alertRef = useRef(null);

  let clname = useMemo(() => {
    return checklist?.Name?.substring(
      customerData?.potential?.Deal_Name?.length
    );
  }, [checklist]);

  const clKey = useMemo(() => {
    return getChecklistToChange(clname || "");
  }, [checklist]);

  // if (clname?.includes("Repair")) {
  //   clname = clname?.replace("Repair", "Items of Interest");
  // }

  const removeChecklist = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const deleteChecklist = useCallback(async () => {
    dispatch(setIsLoading(true));

    // Extract and filter data once
    const checklistToDelete = customerData[clKey]?.filter(
      (cl) => cl?.id === checklist.id
    );
    const [checklistJunctionKey, junctionInsiderKeyToCheck] =
      getChecklistJunction(clKey, "junction");
    const related_to_form_key = getChecklistJunction(
      clKey,
      "related_to_form_key"
    );
    const junctionsToDelete = customerData[checklistJunctionKey]?.filter(
      (junction) => junction[junctionInsiderKeyToCheck]?.id === checklist.id
    );
    const imagesToDelete = getImages(
      checklist,
      customerData,
      related_to_form_key
    );

    // Create a deep copy once
    let updatedCustomerData = JSON.parse(JSON.stringify(customerData));

    // Collect async operations to run them in parallel later
    const asyncOperations = [];

    if (checklistToDelete?.length > 0) {
      const clapiInstance = new ChecklistApiUtil(clKey);
      asyncOperations.push(
        clapiInstance
          .bulkRemoveRecord(checklistToDelete, alertRef.current)
          .then(() => {
            updatedCustomerData =
              ChecklistApiUtil.batchDeleteForModulesInCustomerData(
                updatedCustomerData,
                clKey,
                checklistToDelete
              );
          })
      );
    }

    if (junctionsToDelete?.length > 0) {
      const clapiInstance = new ChecklistApiUtil(checklistJunctionKey);
      asyncOperations.push(
        clapiInstance
          .bulkRemoveRecord(junctionsToDelete, alertRef.current)
          .then(() => {
            updatedCustomerData =
              ChecklistApiUtil.batchDeleteForModulesInCustomerData(
                updatedCustomerData,
                checklistJunctionKey,
                junctionsToDelete
              );
          })
      );
    }

    if (imagesToDelete?.length > 0) {
      const clapiInstance = new ChecklistApiUtil("Field_Visit_Images");
      asyncOperations.push(
        clapiInstance
          .bulkRemoveRecord(imagesToDelete, alertRef.current)
          .then(() => {
            updatedCustomerData =
              ChecklistApiUtil.batchDeleteForModulesInCustomerData(
                updatedCustomerData,
                "Field_Visit_Images",
                imagesToDelete
              );
          })
      );
    }

    // Execute all async operations in parallel
    await Promise.all(asyncOperations);

    // Update stores
    const storesToUpdate = [setCustomerData, setOldCustomerData];
    await Promise.all(
      storesToUpdate.map((store) => dispatch(store(updatedCustomerData)))
    );

    // Update selected checklist if necessary
    if (selectedChecklist?.id === checklist?.id) {
      let newChecklistToSet = null;
      if (updatedCustomerData[clKey]?.length > 0) {
        newChecklistToSet = updatedCustomerData[clKey][0];
      }
      dispatch(setChecklist(newChecklistToSet));
    }

    alertRef.current?.openSnackbar(true, "Checklist deleted!", "success");
    dispatch(setIsLoading(false));
    onClose();
  }, [
    checklist,
    customerData,
    clKey,
    setIsLoading,
    selectedChecklist,
    alertRef,
  ]);

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex gap-2 items-center">
        <DroppableMotionButton
          key={checklist?.id || idx}
          item={checklist}
          type="checklist"
          changeContext={changeContext}
          isSelected={selectedChecklist?.id === checklist?.id}
        >
          <ChecklistNameWithColor cl={checklist} clname={clname} />
        </DroppableMotionButton>
        {selectedMode === "Checklists" && (
          <div className="flex items-center justify-center py-2 flex-col space-y-2">
            <RemoveButton onClick={removeChecklist} />
          </div>
        )}
      </div>
      <PopUpModal
        onCancel={onClose}
        isOpen={isModalOpen}
        header={`Delete Checklist`}
      >
        <DeleteConfirmation
          clName={clname}
          onClose={onClose}
          onDelete={deleteChecklist}
        />
      </PopUpModal>
      <MuiSnackbar ref={alertRef} />
    </>
  );
};

export default ChecklistAccordionChild;
