import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSearchParams } from "react-router-dom";
import { deleteImageInZoho } from "../../services/customer.service";
import { setCustomerData, setUploading } from "../../redux/customer";
import ImageSliderPopup from "./ImageSliderPopup";

const SingleImage = (props) => {
  const [searchParams] = useSearchParams();
  const queryValue = searchParams.get("id");
  const dispatch = useDispatch();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupImages, setPopupImages] = useState([]);
  const [popupInitialSlide, setPopupInitialSlide] = useState(0);

  const { imgUrl, id, index, roomImages } = props;
  const selectedRoom = useSelector(
    (state) => state.customerReducer.selectedRoom
  );
  const selectedChecklist = useSelector(
    (state) => state.customerReducer.selectedChecklist
  );
  const fieldVisitImages = useSelector(
    (state) => state.customerReducer.customerData.Field_Visit_Images
  );

  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );

  const [{ isDragging }, drag, preview] = useDrag({
    type: "ROOM_IMAGE",
    item: {
      room: selectedRoom !== null ? selectedRoom : selectedChecklist,
      id: id,
      imgUrl: imgUrl,
      type: "ROOM_IMAGE",
    },
    end: (item) => {
      console.log(item, "item66", props);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Disable the default drag preview by setting a transparent image
  useEffect(() => {
    if (preview) {

      const img = new Image();
      img.src =
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1'%3E%3C/svg%3E";
      preview(img);
    }
  }, [preview]);

  const handleRemove = async () => {
    dispatch(setUploading(true));
    const response = await deleteImageInZoho(queryValue, {
      module_name: "Field_Visit_Images",
      record_id: id,
    });
    if (response?.status) {
      const updateImageArray = fieldVisitImages?.filter(
        (img) => img?.id !== id
      );
      const updatedCustomerData = {
        ...customerData,
        Field_Visit_Images: updateImageArray,
      };
      dispatch(setCustomerData(updatedCustomerData));
    } else {
      console.log("Error", response);
    }
    dispatch(setUploading(false));
  };

  const handleImageClick = (clickedImage, index) => {
    const images = roomImages?.map((img) => img?.URL) || [];

    setPopupImages(images); // Set images for the popup
    setPopupInitialSlide(index); // Set initial slide based on clicked image index
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupImages([]);
    setPopupInitialSlide(0);
  };

  return (
    <Grid
      item
      sm={4}
      lg={3}
      ref={drag}
      className={isDragging ? "fade-out" : "fade-in"}
      style={{ position: "relative" }}
    >
      <div className="relative w-full pt-[75%] overflow-hidden rounded-md shadow-md">
        <img
          src={imgUrl}
          alt="Room"
          className="absolute top-0 left-0 w-full h-full object-cover rounded-md cursor-pointer"
          onClick={() => handleImageClick(imgUrl, index)}
          onTouchStart={() => handleImageClick(imgUrl, index)}
        />
        <ImageSliderPopup
          images={popupImages}
          initialSlide={popupInitialSlide}
          open={isPopupOpen}
          onClose={closePopup}
        />
      </div>
      <IconButton
        onClick={handleRemove}
        style={{
          position: "absolute",
          top: 10,
          right: 0,
        }}
      >
        <CancelIcon style={{ color: "red" }} />
      </IconButton>
    </Grid>
  );
};

export default SingleImage;
