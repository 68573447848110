import React from "react";

const ChecklistNameWithColor = ({ cl, clname }) => {
  return (
    <div className="flex items-center justify-between">
      <div>{clname}</div>
      {cl?.Color && (
        <div
          className={`p-1 rounded-full w-6 h-6`}
          style={{ backgroundColor: cl?.Color }}
        />
      )}
    </div>
  );
};

export default ChecklistNameWithColor;
