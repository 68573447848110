export const STAIRCASE = [
  [
    {
      question: "Name of Staircase",
      key: "Name_of_Staircase",
      type: "TEXT",
    },
    {
      question: "Scope",
      key: "Scope",
      type: "DROPDOWN",
      option: ["Install", "Refinishing"],
    },
    {
      question: "# of Close Treads",
      key: "No_of_Close_Treads",
      type: "TEXT",
    },
    {
      question: "# of Open Treads",
      key: "No_of_Open_Treads",
      type: "TEXT",
    },
    {
      question: "# of Risers",
      key: "No_of_Risers",
      type: "TEXT",
    },
    {
      question: "# of Posts",
      key: "No_of_Posts",
      type: "TEXT",
    },
    {
      question: "LF of Handrailing",
      key: "LF_of_Handrailing",
      type: "TEXT",
    },
    {
      question: "Exposed/Covered",
      key: "Exposed_Covered",
      type: "TEXT",
    },
    {
      question: "Cove Molding",
      key: "Cove_Molding",
      type: "DROPDOWN",
      option: [
        "No Cove Molding",
        "Cove Molding Painted",
        "Cove Molding Stained",
      ],
    },
    {
      question: "Species",
      key: "Species",
      type: "DROPDOWN",
      option: [
        "White Oak",
        "Red Oak",
        "Southern Yellow Pine",
        "Heart Pine",
        "Eastern White Pine",
        "Pine - Unknown",
        "Maple",
        "Walnut",
        "Brazilian Cherry",
        "Ash",
        "Douglas Fir",
        "Oak - Unknown Type",
        "Other",
      ],
    },
    {
      question: "Staircase Notes",
      key: "Staircase_Notes",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Exposed/Covered",
      key: "Exposed_Covered",
      type: "TEXT",
    },
    {
      question: "KASA Removing?",
      key: "KASA_Removing",
      type: "BOOLEAN",
    },
  ],
];
