export const MOLDING = [
  [
    {
      question: "Height",
      key: "Height",
      type: "TEXT",
      fieldType: "number",
    },
    {
      question: "Field Visit Molding Notes",
      key: "Field_Visit_Molding_Notes",
      type: "TEXT",
    },
  ],
];
