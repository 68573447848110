export const REF_CHECKLIST = [
  [
    {
      question: "Can Floor be buffed & recoated?",
      key: "Can_Floor_be_buffed_recoated",
      type: "BOOLEAN",
    },
    {
      question: "Conf. no waxes or polishes on the floor?",
      key: "Conf_no_waxes_or_polishes_on_the_floor",
      type: "DROPDOWN",
      option: [
        "Test not yet completed",
        "Test was negative",
        "Test was inconclusive",
      ],
    },
    {
      question: "Buffing and Recoating Notes",
      key: "Buffing_and_Recoating_Notes",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Confirmation of enough material for sanding?",
      key: "Confirmation_of_enough_material_for_sanding",
      type: "BOOLEAN",
    },
    {
      question: "Solid / Engineered",
      key: "Solid_Engineered",
      type: "DROPDOWN",
      option: ["Solid", "Engineered"],
    },
    {
      question: "Notes For Enough Material For Sanding",
      key: "Notes_For_Enough_Material_For_Sanding",
      type: "TEXT",
    },
    {
      question: "Details for thickness of wear layer (confirmed to thin)",
      key: "Thickness_Dtls_of_wear_layer_confirmed_to_thin",
      type: "TEXT",
    },
    {
      question: "Wear Layer thickness If Eng.",
      key: "Wear_Layer_thickness_If_Eng",
      type: "DROPDOWN",
      option: ["Solid", "Engineered"],
    },
    // {
    //   question: "Add Images",
    //   key: "Images",
    //   type: "IMAGE",
    // },
  ],
  [
    {
      question: "Excessive damage (ex. pet stains,cracked boards)",
      key: "Excessive_Damage",
      type: "BOOLEAN",
    },
    {
      question: "Pet Stains",
      key: "Pet_Stains",
      type: "BOOLEAN",
    },
    {
      question: "Cracked Boards",
      key: "Cracked_Boards",
      type: "BOOLEAN",
    },
    {
      question: "Required Repairs",
      key: "Required_Repairs",
      type: "BOOLEAN",
    },
    {
      question: "Weave Ins",
      key: "Weave_Ins",
      type: "BOOLEAN",
    },
    {
      question: "Loose Boards",
      key: "Loose_Boards",
      type: "BOOLEAN",
    },
    {
      question: "Floor Sequeaking",
      key: "Floor_Sequeaking",
      type: "BOOLEAN",
    },
    {
      question: "Significant Gaps",
      key: "Significant_Gaps",
      type: "BOOLEAN",
    },
    {
      question: "Other Excessive Damage",
      key: "Other_Excessive_Damage",
      type: "BOOLEAN",
    },
    {
      question: "Note for pet stains",
      key: "Note_For_Pet_Stains",
      type: "TEXT",
    },
    {
      question: "Note for cracked boards",
      key: "Note_For_Cracked_Boards",
      type: "TEXT",
    },
    {
      question: "Note for Required Repairs",
      key: "Note_For_Required_Repairs",
      type: "TEXT",
    },
    {
      question: "Note for Weave Ins",
      key: "Note_For_Weave_Ins",
      type: "TEXT",
    },
    {
      question: "Note for Loose Boards",
      key: "Note_For_Loose_Boards",
      type: "TEXT",
    },
    {
      question: "Note for Floor Sequeaking",
      key: "Note_For_Floor_Sequeaking",
      type: "TEXT",
    },
    {
      question: "Note for Significant Gaps",
      key: "Note_For_Significant_Gaps",
      type: "TEXT",
    },
    {
      question: "Note for Other Excessive Damage",
      key: "Note_For_Other_Excessive_Damage",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Surface issues ( ex. mastic, paint, wax)",
      key: "Surface_Issues",
      type: "BOOLEAN",
    },
    {
      question: "Mastic (Sticky)",
      key: "Mastic_Sticky",
      type: "BOOLEAN",
    },
    {
      question: "Paint",
      key: "Paint",
      type: "BOOLEAN",
    },
    {
      question: "wax",
      key: "wax",
      type: "BOOLEAN",
    },
    {
      question: "Top Nailed Boards",
      key: "Top_Nailed_Boards",
      type: "BOOLEAN",
    },
    {
      question: "Rug Marks",
      key: "Rug_Marks",
      type: "BOOLEAN",
    },
    {
      question: "Other Surface Issue",
      key: "Other_Surface_Issue",
      type: "BOOLEAN",
    },
    {
      question: "Significant Gaps",
      key: "Significant_Gaps",
      type: "BOOLEAN",
    },
    {
      question: "Other Excessive Damage",
      key: "Other_Excessive_Damage",
      type: "BOOLEAN",
    },
    {
      question: "Note for mastic (sticky)",
      key: "Note_For_Mastic_Sticky",
      type: "TEXT",
    },
    {
      question: "Note for Paint",
      key: "Note_For_Paint",
      type: "TEXT",
    },
    {
      question: "Note for wax",
      key: "Note_For_Wax",
      type: "TEXT",
    },
    {
      question: "Note for top Nailed Boards",
      key: "Note_For_Weave_Ins",
      type: "TEXT",
    },
    {
      question: "Note for Rug Marks",
      key: "Note_For_Rug_Marks",
      type: "TEXT",
    },
    {
      question: "Note for Other Surface Issue",
      key: "Note_For_Other_Surface_Issue",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Notes for Refinishing Checklist",
      key: "Refinishing_Checklist_Notes",
      type: "TEXT",
    },
  ],
];
