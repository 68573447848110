import Compressor from "compressorjs";
// coompress image with compressor.js
export const handleImageCompression = async (file) => {
    try {
        const compressedFile = await new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6, // Adjust the quality (0 to 1)
                // maxWidth: 1920,
                // maxHeight: 1920,
                success(result) {
                    // `result` is the compressed Blob or File object
                    resolve(result);
                },
                error(err) {
                    console.error("Image compression error:", err.message);
                    reject(err);
                },
            });
        });

        // Handle the compressedFile as needed (e.g., upload or display)
        return compressedFile;
    } catch (error) {
        console.error("Error compressing the image:", error);
    }
};