import styled from "@emotion/styled";
import React from "react";
import GetIcon from "../../assets/get-icon";
import { Constant } from "../../utils/constants";

const StyledQueInput = styled("div")(({ disable }) => ({
  minHeight: "48px",
  padding: "10px 12px 10px 20px",
  display: "flex !important",
  alignItems: "center",
  position: "relative",
  gap: "10px",
  flexShrink: 0,
  border: "1.5px solid #D8D8D8",
  borderRadius: "10px",
  backgroundColor: disable ? Constant.BG_DISABLE : "#FFF",
  pointerEvents: disable ? "none" : "unset",
}));

const BooleanField = ({ question, value, handleClickBoolean, disable }) => {
  return (
    <StyledQueInput disable={disable}>
      <div className={`w-full text-[13px] text-start`}>{question}</div>
      <div className="w-[30px] h-[30px] flex justify-center items-center">
        {value === null ? (
          <GetIcon
            onClick={handleClickBoolean}
            iconName="default-icon"
            className="cursor-pointer"
          />
        ) : value ? (
          <GetIcon
            onClick={handleClickBoolean}
            iconName="yes-icon"
            className="cursor-pointer"
            data={disable ? { color: Constant.BG_DISABLE } : {}}
          />
        ) : (
          <GetIcon
            onClick={handleClickBoolean}
            iconName="no-icon"
            className="cursor-pointer"
            data={disable ? { color: Constant.BG_DISABLE } : {}}
          />
        )}
      </div>
    </StyledQueInput>
  );
};

export default BooleanField;
