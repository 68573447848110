// Function to get the SVG icon based on the icon name
const GetIcon = ({ iconName, data = {}, onClick, className }) => {
  const { color, width, height, secondColor } = data;
  switch (iconName) {
    case "default-icon":
      return (
        <svg
          width={width || "30"}
          height={height || "30"}
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
          className={className}
        >
          <rect
            x="0.5"
            y="0.5"
            width="29"
            height="29"
            rx="14.5"
            fill="white"
            stroke="#D8D8D8"
          />
          <g filter="url(#filter0_d_717_2486)">
            <circle cx="15" cy="15" r="11" fill="#D8D8D8" />
          </g>
          {/* <path
            d="M17.4031 10.5685H18.9991V20.3125H17.4031L12.6011 13.0465V20.3125H11.0051V10.5685H12.6011L17.4031 17.8205V10.5685Z"
            fill="#505050"
          /> */}
          <defs>
            <filter
              id="filter0_d_717_2486"
              x="2"
              y="2"
              width="28"
              height="28"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_717_2486"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_717_2486"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );
    case "no-icon":
      return (
        <svg
          width={width || "30"}
          height={height || "30"}
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
          className={className}
        >
          <rect
            x="0.5"
            y="0.5"
            width="29"
            height="29"
            rx="14.5"
            fill="white"
            stroke={color || "#D8D8D8"}
          />
          <g filter="url(#filter0_d_717_2486)">
            <circle cx="15" cy="15" r="11" fill={color || "#D8D8D8"} />
          </g>
          <path
            d="M17.4031 10.5685H18.9991V20.3125H17.4031L12.6011 13.0465V20.3125H11.0051V10.5685H12.6011L17.4031 17.8205V10.5685Z"
            fill={color ? "white" : "#505050"}
          />
          <defs>
            <filter
              id="filter0_d_717_2486"
              x="2"
              y="2"
              width="28"
              height="28"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_717_2486"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_717_2486"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );
    case "yes-icon":
      return (
        <svg
          width={width || "31"}
          height={height || "31"}
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
          className={className}
        >
          <rect
            x="0.5"
            y="0.5"
            width="29"
            height="29"
            rx="14.5"
            fill="white"
            stroke={color || "#009DC2"}
          />
          <g filter="url(#filter0_d_831_10498)">
            <circle cx="15" cy="15" r="11" fill={color || "#009DC2"} />
          </g>
          <path
            d="M14.2057 16.472L10.9717 10.27H12.7497L15.0037 15.044L17.2577 10.27H19.0217L15.8017 16.472V20H14.2057V16.472Z"
            fill="white"
          />
          <defs>
            <filter
              id="filter0_d_831_10498"
              x="3"
              y="3"
              width="28"
              height="28"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="2" dy="2" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_831_10498"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_831_10498"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );
    case "dropdown-icon":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.64645 4.64645C1.84171 4.45118 2.15829 4.45118 2.35355 4.64645L8 10.2929L13.6464 4.64645C13.8417 4.45118 14.1583 4.45118 14.3536 4.64645C14.5488 4.84171 14.5488 5.15829 14.3536 5.35355L8.35355 11.3536C8.15829 11.5488 7.84171 11.5488 7.64645 11.3536L1.64645 5.35355C1.45118 5.15829 1.45118 4.84171 1.64645 4.64645Z"
            fill="#505050"
          />
        </svg>
      );

    default:
      return null; // Return null for cases when the icon name is not recognized
  }
};

export default GetIcon;
