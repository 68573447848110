import React from "react";
import Slider from "react-slick";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSliderPopup = React.memo(
    ({ images, initialSlide, open, onClose }) => {
        const settings = {
            initialSlide,
            arrows: false,
            infinite: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        const customDialogContentStyle = {
            height: "410px",
        };

        const customDotStyles = `
            .image-slider-popup .slick-dots {
                bottom: -22px;
            }
            .image-slider-popup .slick-dots li button:before {
                font-size: 16px !important;
            }
            .image-slider-popup .slick-slide {
                height: 366px;
            }
        `;


        return (
            <Dialog open={open} onClose={onClose}>
                <DialogContent style={customDialogContentStyle}>
                    <DialogContentText>
                        <style>{customDotStyles}</style>
                        <Slider className="image-slider-popup" {...settings}>
                            {images?.map((image, index) => (
                                <div key={index} className="flex justify-center relative">
                                    <img
                                        src={image}
                                        alt={`Image ${index}`}
                                        className="mx-auto rounded-xl"
                                        style={{ width: "530px", height: "365px" }}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
);

export default ImageSliderPopup;
