import React, { useCallback } from "react";
import { motion } from "framer-motion";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";

const DragButton = (props) => {
  const { type, clKey, ...rest } = props;

  const [{ isDragging }, drag] = useDrag({
    type: clKey,
    item: { type, clKey },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  const getBgColorForRoom = useCallback(() => {
    if (type === "From") {
      return "bg-[#8EC24A]";
    } else if (type === "To") {
      return "bg-[#F8842F]";
    } else if (type === "Within") {
      return "bg-[#1a65d6]";
    }
  }, [type]);

  return (
    <motion.button
      ref={drag}
      className={`rounded-full shadow-lg !text-white !text-[12px] !font-medium !w-[60px] !h-[60px] ${getBgColorForRoom()} z-[5000]`}
      whileTap={{ scale: 0.95 }}
      animate={{
        opacity: isDragging ? 0.7 : 1,
      }}
      {...rest}
    >
      {type}
    </motion.button>
  );
};

export default DragButton;
