import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/system";
import ChecklistForm from "../checklists/checklist-form";
import { SCOPE } from "../../../utils/scope";
import MotionButton from "../../../components/ui/motion-button";
import DropdownField from "../../../components/question-fields/dropdown";
import NoteField from "../../../components/question-fields/note";
import BooleanField from "../../../components/question-fields/boolean";
import {
  cloneData,
  getQuestionAnswer,
  updateScope,
} from "../../../utils/common-utils";
import MuiSnackbar from "../../../components/ui/mui-snackbar";
import { Constant } from "../../../utils/constants";
import _ from "lodash";
import { setIsLoading, setOldCustomerData } from "../../../redux/customer";
import { updateFVRecordInZoho } from "../../../services/customer.service";

const Scope = (props) => {
  const { customer_po_id } = props;

  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );
  const oldCustomerData = useSelector(
    (state) => state.customerReducer.oldCustomerData
  );
  const scope = useMemo(() => {
    return customerData?.fv_scope;
  }, [customerData]);

  const dispatch = useDispatch();

  const alertRef = useRef(null);

  /**
   * Update the answer in fv_scope object
   */
  const updateAnswer = useCallback(
    (question, answer) => {
      const clonedCustomerData = JSON.parse(JSON.stringify(customerData));
      updateScope(clonedCustomerData, scope, question, answer, dispatch);
    },
    [customerData, scope]
  );

  const shouldQuestionBeRendered = useCallback(
    (queObj) => {
      if (queObj?.shouldRender) return queObj?.shouldRender?.(scope);
      return true;
    },
    [scope]
  );

  const renderQuestions = useCallback(
    (queObj) => {
      const shouldRender = shouldQuestionBeRendered(queObj);

      switch (queObj.type) {
        case "BOOLEAN":
          return (
            <BooleanField
              disable={!shouldRender}
              question={queObj?.question}
              value={scope?.[queObj.key]}
              handleClickBoolean={() => {
                const answer = getQuestionAnswer(scope, queObj, null);
                updateAnswer(queObj, answer);
              }}
            />
          );
        case "TEXT":
          return (
            <NoteField
              question={queObj?.question}
              value={scope?.[queObj.key] || ""}
              handleNoteBlur={(event) => {
                const answer = getQuestionAnswer(scope, queObj, event);
                updateAnswer(queObj, answer);
              }}
              id={queObj?.key}
              name={queObj?.key}
              type={queObj?.fieldType || "text"}
            />
          );
        case "DROPDOWN":
          return (
            <DropdownField
              question={queObj?.question}
              value={scope?.[queObj.key] || ""}
              options={queObj?.option}
              handleChangeDropdown={(event) => {
                const answer = getQuestionAnswer(scope, queObj, event);
                updateAnswer(queObj, answer);
              }}
            />
          );
        default:
          return null;
      }
    },
    [customerData]
  );

  const handleSubmitData = useCallback(async () => {
    dispatch(setIsLoading(true));
    const updateFvScopeBody = {
      module_name: "FV_Scopes",
      record_obj: scope,
    };

    const recordUpdateResponse = await updateFVRecordInZoho(
      customer_po_id,
      updateFvScopeBody
    );

    if (recordUpdateResponse.data?.status) {
      alertRef.current.openSnackbar(true, "Scope udpated!", "success");

      let clonedOldCustomerData = cloneData(oldCustomerData);
      clonedOldCustomerData.fv_scope = scope;

      dispatch(setOldCustomerData(clonedOldCustomerData));
    } else {
      alertRef.current.openSnackbar(
        true,
        recordUpdateResponse.data?.message ||
          "Something went wrong, scope didnt udpated!",
        "error"
      );
    }
    dispatch(setIsLoading(false));
  }, [scope, customerData]);

  const isScopeDataChanged = useMemo(() => {
    const clonedOldCustomerData = cloneData(oldCustomerData);

    const isDataChanged = !_.isEqual(scope, clonedOldCustomerData.fv_scope);
    return isDataChanged;
  }, [scope, oldCustomerData]);

  return (
    <Stack sx={{ m: 3 }}>
      <div className={`flex justify-end items-center mb-2`}>
        <MotionButton
          className={`text-white rounded-md max-w-7 
          ${!isScopeDataChanged ? "bg-gray-200" : Constant.BG_PRIMARY} 
          `}
          disabled={!isScopeDataChanged}
          onClick={handleSubmitData}
        >
          SUBMIT DATA
        </MotionButton>
      </div>
      <ChecklistForm
        selectedChecklist={scope}
        renderQuestions={renderQuestions}
        checklistItems={SCOPE}
        isScope={true}
      />

      <MuiSnackbar ref={alertRef} />
    </Stack>
  );
};

export default Scope;
