import React, { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import {
  setChecklist,
  setCustomerData,
  setIsLoading,
  setOldCustomerData,
  setRoom,
} from "../../redux/customer";
import CustomAccordion from "../../components/accordion";
import DroppableMotionButton from "./DroppableMotionButton";
import {
  getChecklistColor,
  getChecklistToChange,
  isChecklistInScope,
  sortChecklistsBasedOnName,
  updteCustomerDataWithNewChecklist,
} from "../../utils/common-utils";
import {
  CHECKLIST_MAPPING,
  CHECKLIST_NAMES,
  CHECKLIST_THOSE_SHOULD_HAVE_COLOR,
} from "../../utils/constants";
import AddButton from "../../components/ui/add-button";
import { updateFVRecordInZoho } from "../../services/customer.service";
import MuiSnackbar from "../../components/ui/mui-snackbar";
import ChecklistAccordionChild from "../../components/accordion/checklist-accordion";
import { FloorUtil } from "../../utils/floor-utils";

const SidebarContent = () => {
  const selectedMode = useSelector(
    (state) => state.customerReducer.selectedMode
  );
  const selectedChecklist = useSelector(
    (state) => state.customerReducer.selectedChecklist
  );
  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );
  const selectedRoom = useSelector(
    (state) => state.customerReducer.selectedRoom
  );
  const oldCustomerData = useSelector(
    (state) => state.customerReducer.oldCustomerData
  );

  const [searchParams] = useSearchParams();
  const customer_po_id = searchParams.get("id");
  const alertRef = useRef(null);

  const scope = useMemo(() => {
    return customerData?.fv_scope;
  }, [customerData]);

  const dispatch = useDispatch();

  const floorWiseRooms = useMemo(() => {
    let floorInstance = new FloorUtil(customerData);
    return floorInstance.getFloorWiseRooms();
  }, [customerData]);

  const allExistingChecklists = useMemo(() => {
    const clonedCustomerData = JSON.parse(JSON.stringify(customerData));
    const checklistsToReturn = {};

    for (const [key, label] of Object.entries(CHECKLIST_MAPPING)) {
      const items = clonedCustomerData?.[key] || [];
      const shouldChecklistAdd = isChecklistInScope(key, scope);
      if (shouldChecklistAdd) {
        checklistsToReturn[label] = items;
      }
    }

    return checklistsToReturn;
  }, [customerData, scope]);

  const changeContext = (item, context) => {
    if (context === "room") {
      dispatch(setRoom(item));
    } else {
      dispatch(setChecklist(item));
    }
  };

  const addNewChecklist = useCallback(
    async (clName) => {
      dispatch(setIsLoading(true));
      const clonedCustomerData = JSON.parse(JSON.stringify(customerData));
      const newChecklistColor = getChecklistColor(clonedCustomerData, clName);
      const checklistKey = getChecklistToChange(clName);

      const shouldColorAdd =
        CHECKLIST_THOSE_SHOULD_HAVE_COLOR.includes(checklistKey);
      const currentChecklistsLength =
        clonedCustomerData?.[checklistKey]?.length;

      const Potential = {
        id: customerData?.potential?.id || "",
        name: customerData?.potential?.Deal_Name || "",
      };
      const Field_Visit_Project = {
        id: customerData?.fv_project?.id || "",
        name: customerData?.fv_project?.Name || "",
      };

      let createChecklistBody = {
        Name: `${Potential.name} ${CHECKLIST_NAMES[checklistKey]} - ${
          currentChecklistsLength + 1
        }`,
        Potential,
        Field_Visit_Project,
      };

      if (shouldColorAdd) {
        createChecklistBody.Color = newChecklistColor;
      }
      if (checklistKey === "FV_Staircases") {
        createChecklistBody.Name_of_Staircase = `${
          CHECKLIST_NAMES[checklistKey]
        } ${currentChecklistsLength + 1}`;
      }

      const updateRecordBody = {
        module_name: checklistKey,
        record_obj: createChecklistBody,
      };

      const recordUpdateResponse = await updateFVRecordInZoho(
        customer_po_id,
        updateRecordBody
      );
      const result = recordUpdateResponse.data;

      if (result?.status) {
        alertRef.current.openSnackbar(true, "New Record added!", "success");

        const newChecklist = result?.entity?.details?.output?.data;
        updteCustomerDataWithNewChecklist(
          customerData,
          newChecklist,
          checklistKey,
          dispatch,
          setCustomerData
        );
        updteCustomerDataWithNewChecklist(
          oldCustomerData,
          newChecklist,
          checklistKey,
          dispatch,
          setOldCustomerData
        );
      } else {
        alertRef.current.openSnackbar(
          true,
          recordUpdateResponse.data?.message || "Something went wrong!",
          "error"
        );
      }

      dispatch(setIsLoading(false));
    },
    [getChecklistToChange, customerData, updteCustomerDataWithNewChecklist]
  );

  const getAccordionChildren = React.useCallback(
    (items, type, clName) => {
      switch (type) {
        case "rooms":
          if (items?.length === 0) return <></>;
          return items.map((room, idx) => {
            return (
              <DroppableMotionButton
                key={room?.id || idx}
                item={room}
                type="room"
                changeContext={changeContext}
                isSelected={selectedRoom?.Display_Name === room?.Display_Name}
              >
                {room?.Display_Name}
              </DroppableMotionButton>
            );
          });

        case "checklists":
          return (
            <>
              {items.map((cl, idx) => {
                return (
                  <ChecklistAccordionChild
                    ref={alertRef}
                    key={cl?.id}
                    checklist={cl}
                    changeContext={changeContext}
                    idx={idx}
                  />
                );
              })}
              {selectedMode === "Checklists" && (
                <div className="flex items-center justify-center py-2 flex-col space-y-2">
                  <AddButton onClick={() => addNewChecklist(clName)} />
                </div>
              )}
            </>
          );

        default:
          break;
      }
    },
    [
      floorWiseRooms,
      selectedRoom,
      selectedChecklist,
      customerData,
      selectedMode,
    ]
  );

  return (
    <div className="flex flex-col space-y-2 mb-3">
      {selectedMode === "Scope" ? (
        <div className="flex justify-items-center">
          <Card className="p-2 w-full font-bold">SCOPE</Card>
        </div>
      ) : (
        <>
          {selectedMode === "Images" &&
            Object.keys(floorWiseRooms || {})?.map((floor, idx) => {
              return (
                <CustomAccordion
                  key={floor}
                  accordionTitle={floor}
                  accordionChildren={getAccordionChildren(
                    floorWiseRooms?.[floor],
                    "rooms"
                  )}
                />
              );
            })}
          {Object.keys(allExistingChecklists || {})?.map((cl, idx) => {
            const sortedChecklists = sortChecklistsBasedOnName(
              allExistingChecklists?.[cl],
              customerData
            );

            return (
              <CustomAccordion
                key={cl}
                accordionTitle={cl}
                accordionChildren={getAccordionChildren(
                  sortedChecklists,
                  "checklists",
                  cl
                )}
              />
            );
          })}
        </>
      )}
      <MuiSnackbar ref={alertRef} />
    </div>
  );
};

export default SidebarContent;
