import React from "react";
import MotionButton from "../ui/motion-button";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setRoom } from "../../redux/customer";
import { HEADERS } from "../../utils/headers";
import { Constant } from "../../utils/constants";

const HeaderChip = (props) => {
  const { title, idx } = props;
  const selectedMode = useSelector(
    (state) => state.customerReducer.selectedMode
  );
  const dispatch = useDispatch();

  const changeMode = (title) => {
    if (title === "Scope") {
      dispatch(setRoom(null));
    }
    dispatch(setMode(title));
  };

  return (
    <div>
      <MotionButton
        className={`text-black border-[1px] 
        ${
          selectedMode === title
            ? `${Constant.BG_PRIMARY} text-white`
            : "!bg-[##d8cacf87]"
        } 
        ${idx !== HEADERS.length - 1 && "rounded-r-none"}
        ${idx !== 0 && "rounded-l-none"}
        `}
        onClick={() => changeMode(title)}
      >
        {title}
      </MotionButton>
    </div>
  );
};

export default HeaderChip;
