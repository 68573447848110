import React, { forwardRef, useMemo, useRef } from "react";
import { motion } from "framer-motion";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { DropUtil } from "../../utils/drop-util";

const RoomButton = forwardRef((props, ref) => {
  const { className, buttonName, clKey, poId, room, type, ...rest } = props;
  const selectedChecklist = useSelector(
    (state) => state.customerReducer.selectedChecklist
  );
  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );
  const dispatch = useDispatch();
  const curDragItemType = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: [clKey],
    hover: (item) => {
      curDragItemType.current = item.clKey;
    },
    drop: async (item) => {
      if (item.clKey === "FV_Staircases" && type === "floor") {
        DropUtil.updateStaircaseData(
          clKey,
          item,
          customerData,
          selectedChecklist,
          dispatch,
          poId,
          buttonName,
          ref
        );
      } else if (item.clKey === "FV_Transitions" && type === "room") {
        DropUtil.updateTransitionData(
          clKey,
          item,
          customerData,
          selectedChecklist,
          dispatch,
          poId,
          room,
          ref
        );
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const makeAnimate = useMemo(() => {
    if (
      curDragItemType.current === "FV_Transitions" &&
      type === "room" &&
      isOver
    ) {
      return true;
    } else if (
      curDragItemType.current === "FV_Staircases" &&
      type === "floor" &&
      isOver
    ) {
      return true;
    }
    return false;
  }, [curDragItemType, isOver]);

  return (
    <motion.button
      ref={drop}
      className={`flex items-center justify-center rounded-md py-0 pr-[8px] pl-[4px] h-[28px] min-w-[117px] border-[1px] border-[#D8D8D8] ${className}`}
      whileTap={{ scale: 0.9 }}
      animate={{
        scale: makeAnimate ? 1.02 : 1,
        boxShadow: makeAnimate ? "0 4px 8px rgba(0, 0, 0, 0.2)" : 0,
      }}
      {...rest}
    >
      <div className="text-[11px]">{buttonName}</div>
    </motion.button>
  );
});

export default RoomButton;
