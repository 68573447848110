import { Backdrop, LinearProgress } from "@mui/material";
import React from "react";

const BackdropLinearProgress = () => {
  return (
    <Backdrop
      open={true}
      style={{ zIndex: 9999, color: "#fff" }}
      className="!flex !items-start"
    >
      <div style={{ width: "100%" }}>
        <LinearProgress />
      </div>
    </Backdrop>
  );
};

export default BackdropLinearProgress;
