export const EXISTING_MAT = [
  [
    {
      question: "Type",
      key: "Type",
      type: "DROPDOWN",
      option: [
        "Site Finished Solid",
        "Site Finished Engineered",
        "Prefinished Solid",
        "Prefinished Engineered",
        "Unknown",
        "New Installation",
      ],
    },
    {
      question: "Wear Layer of Existing Site Finished Eng. Flooring",
      key: "Wear_Layer_of_Existing_Site_Finished_Eng_Flooring",
      type: "TEXT",
    },
    {
      question: "Unknown Wood Type Notes",
      key: "Unknown_Wood_Type_Notes",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Are there any bevels",
      key: "Are_there_any_bevels",
      type: "BOOLEAN",
    },
    {
      question: "Type of Bevels",
      key: "Type_of_Bevels",
      type: "DROPDOWN",
      option: ["Standard", "V-Notches", "Other"],
    },
    {
      question: "Notes for Standard",
      key: "Notes_for_Standard",
      type: "TEXT",
    },
    {
      question: "Notes for V-Notches",
      key: "Notes_for_V_Notches",
      type: "TEXT",
    },
    {
      question: "Notes for Other",
      key: "Notes_for_Other",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Species",
      key: "Species",
      type: "DROPDOWN",
      option: [
        "White Oak",
        "Red Oak",
        "Southern Yellow Pine",
        "Heart Pine",
        "Eastern White Pine",
        "Pine - Unknown",
        "Maple",
        "Walnut",
        "Brazilian Cherry",
        "Ash",
        "Douglas Fir",
        "Oak - Unknown Type",
        "Other",
      ],
    },
    {
      question: "Unknown Type of Pine Notes",
      key: "Unknown_Type_of_Pine_Notes",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Layout",
      key: "Lay0ut",
      type: "DROPDOWN",
      option: ["Straight", "Diagonal", "Herringbone", "Chevron", "Other"],
    },
    {
      question: "Details of other layout to be refinished",
      key: "Details_of_other_layout_to_be_refinished",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Width",
      key: "Width",
      type: "TEXT",
      fieldType: "number",
    },
  ],
  [
    {
      question: "Thickness",
      key: "Thickness",
      type: "DROPDOWN",
      option: [
        "5/16 inch",
        "3/8 inch",
        "1/2 inch",
        "5/8 inch",
        "9/16 inch",
        "3/4 inch",
        "1 inch",
        "Other",
      ],
    },
  ],
  [
    {
      question: "Installation",
      key: "Installation",
      type: "DROPDOWN",
      option: ["Nailed", "Glued", "Floated", "Unknown"],
    },
  ],
  [
    {
      question: "Exposed or Covered",
      key: "Exposed_or_Covered",
      type: "DROPDOWN",
      option: ["Exposed", "Covered"],
    },
    {
      question: "Type of flooring is covering the hardwood?",
      key: "Type_of_flooring_is_covering_the_hardwood",
      type: "DROPDOWN",
      option: ["Carpeting", "Laminate", "LVT", "Sheet Vinyl", "Tile", "Other"],
    },
    {
      question: "Is KASA removing the existing flooring",
      key: "Is_KASA_removing_the_existing_flooring",
      type: "BOOLEAN",
    },
    {
      question: "What other type of wood covering the floor ?",
      key: "What_other_type_of_wood_covering_the_floor",
      type: "TEXT",
    },
    {
      question: "Other required info. about the material",
      key: "Other_required_info_about_the_material",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Sawn Type",
      key: "Sawn_Type",
      type: "DROPDOWN",
      option: [
        "Plain Sawn",
        "Rift and Quartered",
        "Rift",
        "Quartered",
        "Live Sawn",
        "Rotary Peeled",
      ],
    },
  ],
  [
    {
      question: "Field Visit Existing Material Notes",
      key: "Field_Visit_Existing_Material_Notes",
      type: "TEXT",
    },
  ],
];
