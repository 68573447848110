export const TRANSITION = [
  [
    {
      question: "Type",
      key: "Type",
      type: "DROPDOWN",
      option: [
        "Flush Reducer",
        "Overlap Reducer",
        "Flush Stairnose",
        "Overlap Stairnose",
        "Threshold",
        "T-Molding",
        "Saddle",
        "Carpet Binder",
        "Quarter Round",
        "Vertical Cross Piece",
        "Other",
      ],
    },
    {
      question: "Location",
      key: "Location",
      type: "DROPDOWN",
      option: ["Doorway", "Fireplace", "Backdoor", "Staircase", "Other"],
    },
    {
      question: "Length",
      key: "Length",
      type: "TEXT",
      fieldType: "number",
    },
    {
      question: "Height Diffrence",
      key: "Height_Diffrence",
      type: "TEXT",
      fieldType: "number",
    },
    {
      question: "To Type of Flooring",
      key: "To_Type_of_Flooring",
      type: "DROPDOWN",
      option: [
        "Prefinished Hardwood",
        "Unfinished Hardwood",
        "Laminate",
        "LVT",
        "Tile",
        "Carpet",
        "Other",
      ],
    },
    {
      question: "Transition Notes",
      key: "Transition_Notes",
      type: "TEXT",
    },
  ],
];
