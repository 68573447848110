import { EXISTING_MAT } from "./existing-material";
import { LEVELLING } from "./levelling";
import { MATCHING_INS } from "./matching-installation";
import { MATCHING_REF } from "./matching-refinishing";
import { MOLDING } from "./molding";
import { REF_CHECKLIST } from "./refinishing-checklist";
import { REPAIR } from "./repair";
import { STAIRCASE } from "./staircase";
import { SUBFLOOR_DETAILS } from "./subfloor-details";
import { TRANSITION } from "./transition";

export const Constant = {
  BG_PRIMARY: "!bg-[#007FFF]",
  BG_DISABLE: "#80808040",
};

export const CHECKLIST_MAPPING = {
  FV_Refinishing_Checklists: "Refinishing Checklists",
  FV_Existing_Materials: "Existing Materials",
  FV_Subfloor_Details: "Subfloor Details",
  FV_Moldings: "Moldings",
  FV_Matching_Refinishings: "Matching Refinishings",
  FV_Matching_Installations: "Matching Installations",
  FV_Levelings: "Levelings",
  FV_Repairs: "Repairs",
  FV_Staircases: "Staircases",
  FV_Transitions: "Transitions",
};

export const CHECKLIST_NAMES = {
  FV_Refinishing_Checklists: "Refinishing Checklist",
  FV_Existing_Materials: "Existing Material",
  FV_Subfloor_Details: "Subfloor Detail",
  FV_Moldings: "Molding",
  FV_Matching_Refinishings: "Matching Refinishing",
  FV_Matching_Installations: "Matching Installation",
  FV_Levelings: "Leveling",
  FV_Repairs: "Repair",
  FV_Staircases: "Staircase",
  FV_Transitions: "Transition",
};

export const CHECKLIST_THOSE_SHOULD_HAVE_COLOR = [
  "FV_Refinishing_Checklists",
  "FV_Existing_Materials",
  "FV_Subfloor_Details",
  "FV_Moldings",
  "FV_Matching_Refinishings",
  "FV_Matching_Installations",
];

export const CHECKLIST_JUNCTION_MAP = {
  FV_Refinishing_Checklists: {
    checklist: REF_CHECKLIST,
    junction: ["Rooms_x_Refinishing", "Field_Visit_Refinishing_Checklist"],
    related_to_form_key: "Refinishing Checklist",
  },
  FV_Existing_Materials: {
    checklist: EXISTING_MAT,
    junction: ["Rooms_x_Exist_Materials", "Field_Visit_Existing_Material"],
    related_to_form_key: "Existing Material",
  },
  FV_Subfloor_Details: {
    checklist: SUBFLOOR_DETAILS,
    junction: ["Rooms_x_Subfloor_Details", "Field_Visit_Subfloor_Details"],
    related_to_form_key: "Subfloor Detail",
  },
  FV_Moldings: {
    checklist: MOLDING,
    junction: ["Rooms_x_Moldings", "Field_Visit_Molding"],
    related_to_form_key: "Molding",
  },
  FV_Matching_Refinishings: {
    checklist: MATCHING_REF,
    junction: ["Rooms_x_Matching_Refi", "Field_Visit_Matching_Refinishing"],
    related_to_form_key: "Matching Refinishing",
  },
  FV_Matching_Installations: {
    checklist: MATCHING_INS,
    junction: ["Rooms_x_Matching_Installs", "Field_Visit_Matching_Install"],
    related_to_form_key: "Matching Installation",
  },
  FV_Levelings: {
    checklist: LEVELLING,
    junction: ["Rooms_x_Levelings", "Field_Visit_Leveling"],
    related_to_form_key: "Leveling",
  },
  FV_Staircases: { checklist: STAIRCASE, related_to_form_key: "Staircase" },
  FV_Repairs: { checklist: REPAIR, related_to_form_key: "Repair" },
  FV_Transitions: { checklist: TRANSITION, related_to_form_key: "Transition" },
};

export const FLOOR_CLICK_SHOULD_NOT_WORK_FOR = [
  "FV_Levelings",
  "FV_Repairs",
  "FV_Staircases",
  "FV_Transitions",
];

export const SHOULD_APPLY_WHITE_BG = [
  "FV_Repairs",
  "FV_Transitions",
  "FV_Staircases",
];

export const FROM_TO_WHITHIN_BTN_SHOW = ["FV_Transitions", "FV_Staircases"];

export const CHECKLIST_DEFAULT_COLORS = [
  "#1E2E5A",
  "#F9832C",
  "#004E6A",
  "#607D8B",
  "#795548",
];
