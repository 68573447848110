export const SCOPE = [
  [
    {
      question: "Furniture",
      key: "Furniture",
      type: "BOOLEAN",
    },
    {
      question: "KASA is Moving",
      key: "KASA_is_Moving",
      type: "BOOLEAN",
      shouldRender: (scope) => scope?.Furniture === true,
    },
    {
      question: "Appliance",
      key: "Appliance",
      type: "BOOLEAN",
      shouldRender: (scope) =>
        scope?.Furniture === true && scope?.KASA_is_Moving === true,
    },
    {
      question: "Special Items",
      key: "Special_Items",
      type: "BOOLEAN",
      shouldRender: (scope) =>
        scope?.Furniture === true && scope?.KASA_is_Moving === true,
    },
  ],
  [
    {
      question: "Refinishing",
      key: "Refinishing",
      type: "BOOLEAN",
    },
    {
      question: "Matching For Refinishing",
      key: "Matching_For_Refinishing",
      type: "BOOLEAN",
      shouldRender: (scope) => scope?.Refinishing === true,
    },
  ],
  [
    {
      question: "Installation",
      key: "Installation",
      type: "BOOLEAN",
    },
    {
      question: "Matching For Installation",
      key: "Matching_For_Installation",
      type: "BOOLEAN",
      shouldRender: (scope) => scope?.Installation === true,
    },
    {
      question: "Levelling For Installation",
      key: "Levelling_For_Installation",
      type: "BOOLEAN",
      shouldRender: (scope) => scope?.Installation === true,
    },
  ],
  [
    {
      question: "Repair",
      key: "Repair",
      type: "BOOLEAN",
    },
    {
      question: "Staircase",
      key: "Staircase",
      type: "BOOLEAN",
    },
    {
      question: "Transition",
      key: "Transition",
      type: "BOOLEAN",
    },
  ],
];
