import React from "react";

const NoContentContainer = (props) => {
  const { children } = props;
  return (
    <div
      style={{ height: "calc(100vh - 148px)" }}
      className="flex justify-center items-center"
    >
      <div className="w-3/4 rounded-md border-dashed border-gray-400 text-xl border-[1px] p-10">
        {children}
      </div>
    </div>
  );
};

export default NoContentContainer;
