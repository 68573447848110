export let TESTROOMS = [
  {
    id: 1,
    name: "Kitchen",
    images: [
      "https://images.woodenstreet.de/image/data/modular%20kitchen/22.jpg",
      "https://images.woodenstreet.de/image/data/modular%20kitchen/22.jpg",
      "https://images.woodenstreet.de/image/data/modular%20kitchen/22.jpg",
      "https://images.woodenstreet.de/image/data/modular%20kitchen/22.jpg",
    ],
  },
  {
    id: 1,
    name: "FR",
    images: [
      "https://hips.hearstapps.com/hmg-prod/images/alexander-design-contemporary-family-room-1555952765.jpg",
      "https://hips.hearstapps.com/hmg-prod/images/alexander-design-contemporary-family-room-1555952765.jpg",
      "https://hips.hearstapps.com/hmg-prod/images/alexander-design-contemporary-family-room-1555952765.jpg",
      "https://hips.hearstapps.com/hmg-prod/images/alexander-design-contemporary-family-room-1555952765.jpg",
    ],
  },
  {
    id: 1,
    name: "Bath",
    images: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRukfbI1tsJu8tG8YWEwvM_-aMdXY1oI38v_g&s",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRukfbI1tsJu8tG8YWEwvM_-aMdXY1oI38v_g&s",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRukfbI1tsJu8tG8YWEwvM_-aMdXY1oI38v_g&s",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRukfbI1tsJu8tG8YWEwvM_-aMdXY1oI38v_g&s",
    ],
  },
];
