import * as HttpService from "./http.service";
import {
  DELETE_IMAGES_IN_ZOHO,
  GET_FILE_FROM_URL,
  GET_REFINEMENT_DATA_BY_ID,
  GET_USER_BY_ID,
  PUSH_IMAGES_TO_ZOHO,
  UPDATE_FV_RECORD_IN_ZOHO,
  UPLOAD_IMAGE,
} from "./url.service";

export const getRefinementData = (id) => {
  return HttpService.getWithOutAuth(GET_REFINEMENT_DATA_BY_ID(id));
};

export const getFileFromUrl = (file, fileName) => {
  return HttpService.postWithOutAuth(GET_FILE_FROM_URL(), { file, fileName });
};

export const getUserById = (id) => {
  return HttpService.getWithOutAuth(GET_USER_BY_ID(id));
};

export const uploadImage = (img, setProgress) => {
  return HttpService.postWithAuthImg(UPLOAD_IMAGE(), img, setProgress);
};
export const ImageObjectToZoho = (id, obj) => {
  return HttpService.postWithAuth(PUSH_IMAGES_TO_ZOHO(id), obj);
};

export const updateFVRecordInZoho = (id, obj) => {
  return HttpService.postWithAuth(UPDATE_FV_RECORD_IN_ZOHO(id), obj);
};

export const deleteImageInZoho = (id, obj) => {
  return HttpService.postWithAuth(DELETE_IMAGES_IN_ZOHO(), obj);
};

export const deleteRecordFromZoho = (obj) => {
  return HttpService.postWithAuth(DELETE_IMAGES_IN_ZOHO(), obj);
};
