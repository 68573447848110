export class CreateRecord {
  constructor(recordData) {
    const { selectedChecklist, room, customerData, junctionInsiderKeyToCheck } =
      recordData;

    const Potential = {
      id: customerData?.potential?.id || "",
      name: customerData?.potential?.Deal_Name || "",
    };
    const Field_Visit_Project = {
      id: customerData?.fv_project?.id || "",
      name: customerData?.fv_project?.Name || "",
    };

    // record to add in junction module
    this.record = {
      Field_Visit_Room: {
        name: room.Name || "",
        id: room.id,
      },
      Potential,
      Field_Visit_Project,
      [junctionInsiderKeyToCheck]: {
        name: selectedChecklist.Name || "",
        id: selectedChecklist.id,
      },
    };
  }

  getNewRecord() {
    return this.record;
  }
}
