import React from "react";
import { Box, Button, Typography } from "@mui/material";

const DeleteConfirmation = (props) => {
  const { clName, onClose, onDelete } = props;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          id="modal-description"
          sx={{ px: 4, py: 2, fontSize: "15px" }}
        >
          Are you sure you want to delete{" "}
          <span className="font-semibold">{clName}</span>
        </Typography>
      </Box>
      <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{
            bgcolor: "#1976D2",
            color: "white",
            marginRight: 1,
            fontSize: "12px",
            "&:hover": {
              backgroundColor: "#1976D2",
            },
          }}
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          // disabled={loading?.["No"]}
          sx={{
            border: "1px solid #1976D2",
            color: "black",
            fontSize: "12px",
          }}
          onClick={onDelete}
        >
          YES
        </Button>
      </Box>
    </>
  );
};

export default DeleteConfirmation;
