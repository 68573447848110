import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useDrop } from "react-dnd";
import { Constant } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerData, setUploading } from "../../redux/customer";
import { useSearchParams } from "react-router-dom";
import { ImageObjectToZoho } from "../../services/customer.service";

const DroppableMotionButton = ({
  item,
  type,
  changeContext,
  isSelected,
  children,
  className,
  ...rest
}) => {
  const [searchParams] = useSearchParams();
  const queryValue = searchParams.get("id");
  const fieldVisitImages = useSelector(
    (state) => state.customerReducer.customerData.Field_Visit_Images
  );
  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );
  const dispatch = useDispatch();

  const [{ isOver }, drop] = useDrop({
    accept: "ROOM_IMAGE",
    drop: async ({ type, room, id }) => {
      dispatch(setUploading(true));
      let fvImages = [...fieldVisitImages];
      const findIndex = fieldVisitImages?.findIndex((img) => img?.id === id);
      const Potential = {
        id: customerData?.potential?.id || "",
        name: customerData?.potential?.Deal_Name || "",
      };
      const FV_Projects = {
        id: customerData?.fv_project?.id || "",
        name: customerData?.fv_project?.Name || "",
      };

      if (item?.Field_Visit_Floor) {
        if (findIndex !== -1) {
          const totalSameRoomImage = fieldVisitImages?.filter((rooms) =>
            rooms?.Name?.includes(item?.Name)
          );
          const updatedImgObj = {
            ...fvImages[findIndex],
            Field_Visit_Room: { id: item.id, name: item.Name },
            Field_Visit_Floor: item?.Field_Visit_Floor,
            Related_To_Form: "Rooms",
            Field_Name: null,
            Sub_Field_Name: null,
            Name: `${item?.Name} Image- ${totalSameRoomImage?.length + 1}`,
          };
          let response = await ImageObjectToZoho(queryValue, {
            module_name: "Field_Visit_Images",
            record_obj: updatedImgObj,
          });
          // fvImages[findIndex] = {
          //     ...fvImages[findIndex],
          //     Field_Visit_Room: { id: item.id, name: item.Name },
          //     Field_Visit_Floor: item?.Field_Visit_Floor,
          //     Related_To_Form: "Rooms",
          //     Field_Name: null,
          //     Sub_Field_Name: null,
          //     Name: `${item?.Name} Image- ${totalSameRoomImage?.length + 1}`,
          // };
          fvImages[findIndex] = response?.data?.entity?.details?.output?.data;
          const updatedCustomerData = {
            ...customerData,
            Field_Visit_Images: fvImages,
          };

          dispatch(setCustomerData(updatedCustomerData));
        }
        dispatch(setUploading(false));
      } else if (!item?.Field_Visit_Floor) {
        dispatch(setUploading(true));
        const extractSubfloorDetail = (fullName) => {
          const parts = fullName.split("-").map((part) => part.trim());
          if (parts.length > 1) {
            return parts.slice(1).join(" - ");
          }
          return "";
        };
        const extractedDetail = extractSubfloorDetail(item?.Name);

        if (findIndex !== -1) {
          let newString =
            extractedDetail?.includes("Matching Installation") ||
            extractedDetail?.includes("Refinishing Checklist") ||
            extractedDetail?.includes("Subfloor Detail")
              ? extractedDetail.replace("- ", "")
              : extractedDetail;
          const totalSameCkListImage = fieldVisitImages?.filter((cklist) =>
            cklist?.Name?.includes(newString)
          );

          const Related_To_Form_Name = extractedDetail?.includes(
            "Refinishing Checklist"
          )
            ? "Refinishing Checklist"
            : extractedDetail?.includes("Existing Material")
            ? "Existing Material"
            : extractedDetail?.includes("Subfloor Detail")
            ? "Subfloor Detail"
            : extractedDetail?.includes("Molding")
            ? "Molding"
            : extractedDetail?.includes("Matching Refinishing")
            ? "Matching Refinishing"
            : extractedDetail?.includes("Matching Installation")
            ? "Matching Installation"
            : extractedDetail?.includes("Leveling")
            ? "Leveling"
            : extractedDetail?.includes("Transition")
            ? "Transition"
            : extractedDetail?.includes("Staircase")
            ? "Staircase"
            : extractedDetail?.includes("Repair")
            ? "Repair"
            : "";

          fvImages[findIndex] = {
            ...fvImages[findIndex],
            Field_Visit_Room: null,
            Field_Name:
              extractedDetail?.includes("Transition") ||
              extractedDetail?.includes("Staircase") ||
              extractedDetail?.includes("Repair") ||
              extractedDetail?.includes("Matching Refinishing") ||
              extractedDetail?.includes("Molding") ||
              extractedDetail?.includes("Leveling")
                ? extractedDetail
                : null,
            Name: `${newString} # Image-${totalSameCkListImage?.length + 1}`,
            Related_To_Form: Related_To_Form_Name || "",
            Sub_Field_Name: null,
          };
          delete fvImages[findIndex].Field_Visit_Floor;

          let response = await ImageObjectToZoho(queryValue, {
            module_name: "Field_Visit_Images",
            record_obj: fvImages[findIndex],
          });
          fvImages[findIndex] = response?.data?.entity?.details?.output?.data;

          const updatedCustomerData = {
            ...customerData,
            Field_Visit_Images: fvImages,
          };
          // dispatch(setCustomerData(updatedCustomerData));
          dispatch(setCustomerData(updatedCustomerData));
        }
        dispatch(setUploading(false));
      }

      console.log(`Dropped item with id: ${item.id}`, room, item);
      // changeContext(item, type);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <motion.button
      ref={drop}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: isOver ? 1.2 : 1 }}
      className={`p-[10px] text-[12px] shadow-md rounded-lg min-w-[120px] w-full text-start border-[1px] border-gray-100 ${
        isSelected ? `${Constant.BG_PRIMARY} text-white` : "bg-white"
      } ${isOver ? "!bg-blue-500 text-white" : ""} ${className}`}
      onClick={() => changeContext(item, type)}
      {...rest}
    >
      {children}
    </motion.button>
  );
};

DroppableMotionButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  changeContext: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default DroppableMotionButton;
