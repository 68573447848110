import { Snackbar } from "@mui/material";
import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef((props, ref) => {
  return (
    <MuiAlert elevation={6} ref={ref} variant={props.variant} {...props} />
  );
});

const MuiSnackbar = forwardRef(
  (
    {
      duration = 8000,
      variant = "filled",
      vertical = "bottom",
      horizontal = "center",
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState("");
    const [message, setMessage] = React.useState("");

    useImperativeHandle(ref, () => ({
      openSnackbar: (open, message, type) => {
        setMessage(message);
        setType(type);
        setOpen(open);
      },
    }));

    const onClose = () => {
      setOpen(false);
      setMessage("");
      setType("");
    };

    return (
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={onClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={onClose}
          severity={type}
          sx={{ width: "100%" }}
          variant={variant}
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }
);

export default MuiSnackbar;

MuiSnackbar.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  open: PropTypes.bool,
  duration: PropTypes.number,
  onClose: PropTypes.func,
};
