import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import WithAuth from "../components/auth/with-auth";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Navigate to="/9Qpb7u7kH4pVuIz" />} />
        <Route
          path="/9Qpb7u7kH4pVuIz"
          element={
            <WithAuth>
              <Home />
            </WithAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
