import React from "react";
import HeaderChip from "../../components/header/header-chip";
import { HEADERS } from "../../utils/headers";

const Topbar = (props) => {
  const { className } = props;

  return (
    <div
      className={`z-20 w-full h-14 border-b border-gray-200 flex justify-center items-center ${className}`}
    >
      {HEADERS.map((header, idx) => (
        <HeaderChip key={idx} title={header} idx={idx} />
      ))}
    </div>
  );
};

export default Topbar;
