import _ from "lodash";

export class RoomBgColor {
  constructor() {
    this.bgColor = "";
    this.textColor = "white";
  }

  getBgColorForRoom(room, roomsWithinSelectedChecklist) {
    const roomWithCurrentCL = roomsWithinSelectedChecklist?.find(
      (clRoom) => clRoom?.id === room?.id
    );

    if (roomWithCurrentCL) {
      this.bgColor = roomWithCurrentCL.bgColor;
    } else {
      this.bgColor = "red";
    }

    return {
      backgroundColor: this.bgColor,
      color: this.textColor,
    };
  }

  getBgColorForRoomInRepair(room, selectedChecklist) {
    const roomWithCurrentCL = selectedChecklist.Within_Room?.id === room?.id;
    if (roomWithCurrentCL) {
      this.bgColor = "#007FFF";
      this.textColor = "white";
    } else {
      this.bgColor = "white";
      this.textColor = "black";
    }

    return {
      backgroundColor: this.bgColor,
      color: this.textColor,
    };
  }

  getBgColorForRoomInLeveling(room, customerData, selectedChecklist) {
    const roomWithCurrentLeveling = _.find(
      customerData?.Rooms_x_Levelings,
      (l) =>
        l.Field_Visit_Leveling.id === selectedChecklist.id &&
        l.Field_Visit_Room.id === room.id
    );
    if (roomWithCurrentLeveling) {
      this.bgColor = "#007FFF";
      this.textColor = "white";
    } else {
      this.bgColor = "white";
      this.textColor = "black";
    }

    return {
      backgroundColor: this.bgColor,
      color: this.textColor,
    };
  }

  getBgColorForRoomInStaircase(floorName, selectedChecklist) {
    const fromFloorWithCurrentST =
      selectedChecklist.From_Floor?.name === floorName;
    const tofromFloorWithCurrentST =
      selectedChecklist.To_Floor?.name === floorName;

    if (fromFloorWithCurrentST) {
      this.bgColor = "#8EC24A";
    } else if (tofromFloorWithCurrentST) {
      this.bgColor = "#F8842F";
    } else {
      this.bgColor = "white";
    }

    if (fromFloorWithCurrentST || tofromFloorWithCurrentST) {
      this.textColor = "white";
    } else {
      this.textColor = "black";
    }

    return {
      backgroundColor: this.bgColor,
      color: this.textColor,
    };
  }

  getBgColorForRoomInTransition(room, selectedChecklist) {
    const fromRoomWithCurrentTranstion =
      selectedChecklist.From_Room?.id === room.id;
    const toRoomWithCurrentTranstion =
      selectedChecklist.To_Room?.id === room.id;
    const withinRoomWithCurrentTranstion =
      selectedChecklist.Within_Room?.id === room.id;

    if (fromRoomWithCurrentTranstion) {
      this.bgColor = "#8EC24A";
    } else if (toRoomWithCurrentTranstion) {
      this.bgColor = "#F8842F";
    } else if (withinRoomWithCurrentTranstion) {
      this.bgColor = "#1a65d6";
    }

    if (
      fromRoomWithCurrentTranstion ||
      toRoomWithCurrentTranstion ||
      withinRoomWithCurrentTranstion
    ) {
      this.textColor = "white";
    } else {
      this.textColor = "black";
    }

    return {
      backgroundColor: this.bgColor,
      color: this.textColor,
    };
  }
}
