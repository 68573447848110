import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getRefinementData } from "../../services/customer.service";
import { useSearchParams } from "react-router-dom";
import { setCustomerData, setOldCustomerData } from "../../redux/customer";
import Images from "./images";
import { LinearProgress } from "@mui/material";
import Checklists from "./checklists";
import Scope from "./scope";

const Home = () => {
  const [searchParams] = useSearchParams();
  const customer_po_id = searchParams.get("id");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const selectedMode = useSelector(
    (state) => state.customerReducer.selectedMode
  );

  const fetchCustomerData = useCallback(async () => {
    const data = await getRefinementData(customer_po_id);
    if (data?.data?.status) {
      dispatch(setCustomerData(data?.data?.entity?.details?.output));

      // set this because, we are comparing the old and updated data to update the render of submit button
      dispatch(setOldCustomerData(data?.data?.entity?.details?.output));
      setLoading(false);
    }
  }, [customer_po_id]);

  useEffect(() => {
    fetchCustomerData();
  }, [fetchCustomerData]);

  return (
    <>
      {loading ? (
        <div>
          <LinearProgress />
        </div>
      ) : (
        <Layout>
          {selectedMode === "Images" && <Images />}
          {selectedMode === "Checklists" && (
            <Checklists
              customer_po_id={customer_po_id}
              fetchCustomerData={fetchCustomerData}
            />
          )}
          {selectedMode === "Scope" && (
            <Scope customer_po_id={customer_po_id} />
          )}
        </Layout>
      )}
    </>
  );
};

export default Home;
