import {
  Backdrop,
  Button,
  CircularProgress,
  Fab,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import SingleImage from "../../components/ui/single-image";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerData } from "../../redux/customer";
import { handleImageCompression } from "../../helper";
import {
  ImageObjectToZoho,
  uploadImage,
} from "../../services/customer.service";
import { useSearchParams } from "react-router-dom";
import { Constant } from "../../utils/constants";
import NoContentContainer from "../../components/ui/no-content";
import BackdropLinearProgress from "../../components/ui/backdrop-linear-progress";

const RoomImages = (props) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queryValue = searchParams.get("id");
  const selectedRoom = useSelector(
    (state) => state.customerReducer.selectedRoom
  );

  const fieldVisitImages = useSelector(
    (state) => state.customerReducer.customerData.Field_Visit_Images
  );
  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );
  const selectedChecklist = useSelector(
    (state) => state.customerReducer.selectedChecklist
  );

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const fileInputRef = useRef(null);
  const { roomImages } = props;
  console.log("roomImages-", roomImages);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return; // Handle no file selected

    handleImageUpload(selectedFile);
  };
  const handleImageUpload = async (imageFile) => {
    if (!imageFile) return; // Prevent upload if no image selected
    setUploading(true);

    const compressedFile = await handleImageCompression(imageFile);

    let formData = new FormData();
    formData.append("file", compressedFile);
    formData.append("originalname", imageFile?.name);

    let imageUploaded = await uploadImage(formData, setProgress);
    if (imageUploaded.data.status) {
      if (selectedRoom) {
        const totalSameRoomImage = fieldVisitImages?.filter((rooms) =>
          rooms?.Name?.includes(selectedRoom?.Name)
        );
        const Potential = {
          id: customerData?.potential?.id || "",
          name: customerData?.potential?.Deal_Name || "",
        };
        const FV_Projects = {
          id: customerData?.fv_project?.id || "",
          name: customerData?.fv_project?.Name || "",
        };

        const newImage = {
          Field_Visit_Room: { name: selectedRoom?.Name, id: selectedRoom?.id },
          Related_To_Form: "Rooms",
          URL: imageUploaded?.data?.entity,
          Name: `${selectedRoom?.Name} Image- ${
            totalSameRoomImage?.length + 1
          }`,
          Potential: Potential,
          Field_Visit_Project: FV_Projects,
        };

        let response = await ImageObjectToZoho(queryValue, {
          module_name: "Field_Visit_Images",
          record_obj: newImage,
        });
        console.log("response", response);
        let fvImages = [
          ...fieldVisitImages,
          response?.data?.entity?.details?.output?.data,
        ];

        const updatedCustomerData = {
          ...customerData,
          Field_Visit_Images: fvImages,
        };
        dispatch(setCustomerData(updatedCustomerData));
      }
    } else {
      console.log("ERROR", imageUploaded?.data?.message);
    }
    setUploading(false);
    setProgress(0);
  };
  console.log("fieldVisitImages", fieldVisitImages);
  const handleFabClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div
        className={`flex justify-end items-center ${uploading && "blur"} mb-2`}
      >
        <input
          style={{ display: "none" }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
        />
        <Fab
          color="secondary"
          sx={{ borderRadius: "8px" }}
          className={`${Constant.BG_PRIMARY}`}
          size="small"
          component="span"
          aria-label="add"
          variant="extended"
          onClick={handleFabClick}
        >
          + Add Image
        </Fab>
      </div>

      {roomImages?.length > 0 ? (
        <Grid container spacing={2}>
          {roomImages?.map((img, idx) => (
            <SingleImage
              key={idx}
              imgUrl={img.URL}
              id={img.id}
              index={idx}
              roomImages={roomImages}
            />
          ))}
        </Grid>
      ) : (
        <NoContentContainer>NO IMAGES</NoContentContainer>
      )}

      {uploading && <BackdropLinearProgress />}
    </>
  );
};

export default RoomImages;
