import React from "react";
import { motion } from "framer-motion";

const AddButton = (props) => {
  const { ...rest } = props;
  return (
    <motion.button whileTap={{ scale: 0.95 }} {...rest}>
      <div
        className={`flex items-center justify-center p-3 rounded-full w-8 h-8 bg-[#007efdad]`}
      >
        <span className="text-white text-lg">+</span>
      </div>
    </motion.button>
  );
};

export default AddButton;
