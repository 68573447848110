import React from "react";
import Topbar from "./topbar";
import Sidebar from "./sidebar";
import { useSelector } from "react-redux";
import BackdropLinearProgress from "../components/ui/backdrop-linear-progress";

const Layout = ({ children }) => {
  const isLoading = useSelector((state) => state.customerReducer.isLoading);

  return (
    <>
      {isLoading && <BackdropLinearProgress />}
      <div
        className={`h-full w-full relative overflow-hidden ${
          isLoading && "opacity-50 pointer-events-none"
        } `}
      >
        <Topbar />
        <div
          className="w-full h-full flex"
          style={{ height: "calc(100% - 56px" }}
        >
          <Sidebar />
          <main className="flex flex-1">
            <div className="!w-auto overflow-auto flex-1">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
