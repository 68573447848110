export const SUBFLOOR_DETAILS = [
  [
    {
      question: "Type",
      key: "Type",
      type: "DROPDOWN",
      option: ["Wood", "Concrete"],
    },
    {
      question: "Type of wood Subfloor",
      key: "Type_of_wood_Subfloor",
      type: "DROPDOWN",
      option: [
        "Plywood (or OSB)",
        "Diagonal Pine",
        "Straight Pine",
        "Particle Board",
        "Other",
      ],
    },
    {
      question: "Type of concrete subfloor",
      key: "Type_of_concrete_subfloor",
      type: "DROPDOWN",
      option: ["Building Concrete", "Slab Concrete", "Other"],
    },
    {
      question: "Any moisture identified?",
      key: "Any_moisture_identified",
      type: "DROPDOWN",
      option: ["Yes", "No", "Unknown"],
    },
    {
      question: "Joist Spacing",
      key: "Joist_Spacing",
      type: "DROPDOWN",
      option: [
        "16 inch",
        "16-19.2 inches",
        "19.2-24 inches",
        "Unknown",
        "Other",
      ],
    },
    {
      question: "Is this a new slab?",
      key: "Is_this_a_new_slab",
      type: "DROPDOWN",
      option: ["Yes", "No", "Unknown"],
    },
    {
      question: "Any cracks identified?",
      key: "Any_cracks_identified",
      type: "DROPDOWN",
      option: ["Yes", "No", "Currently Covered"],
    },
    {
      question: "Any other non leveling concerns w/ concrete subfloor",
      key: "Any_other_non_leveling_concerns_w_concrete_subf",
      type: "TEXT",
    },
    {
      question: "Plywood Type and Thickness",
      key: "Plywood_Type_and_Thickness",
      type: "DROPDOWN",
      option: [
        "3/4 inch CDX Plywood",
        "5/8 inch CDX Plywood",
        "3.23/32 inch OSB",
        "Plywood unknown thickness",
        "OSB Unknown Thickness",
        "Other",
      ],
    },
    {
      question: "Description of other plywood type",
      key: "Description_of_other_plywood_type",
      type: "TEXT",
    },
    {
      question: "Which direction is the pine running?",
      key: "Which_direction_is_the_pine_running",
      type: "DROPDOWN",
      option: [
        "Front Door to Back of the Home",
        "Side to Side of the Home",
        "Other",
      ],
    },
    {
      question: "Describe other direction of straight pine",
      key: "Describe_other_direction_of_straight_pine",
      type: "TEXT",
    },
    {
      question: "Other type of concrete",
      key: "Other_type_of_concrete",
      type: "TEXT",
    },
    {
      question: "Notes about moisture found",
      key: "Notes_about_moisture_found",
      type: "TEXT",
    },
    {
      question: "Notes about unknown moisture",
      key: "Notes_about_unknown_moisture",
      type: "TEXT",
    },
    {
      question: "Other Joist Spacing Details",
      key: "Other_Joist_Spacing_Details",
      type: "TEXT",
    },
    {
      question: "Notes about slab",
      key: "Notes_about_slab",
      type: "TEXT",
    },
    {
      question: "Any other non leveling concerns w/ concrete subfloor",
      key: "Any_other_non_leveling_concerns_w_concrete_subf",
      type: "TEXT",
    },
    {
      question: "Any other non leveling concerns w/ concrete subfloor",
      key: "Any_other_non_leveling_concerns_w_concrete_subf",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Floor Covering",
      key: "Floor_Covering",
      type: "DROPDOWN",
      option: [
        "Wood",
        "Laminate",
        "LVT",
        "Glue Down Vinyl",
        "Sheet Vinyl",
        "Tile",
        "Carpet",
        "Second Plywood Layer",
        "Multiple",
        "Other",
        "None",
      ],
    },
    {
      question: "Type of Wood Floor",
      key: "Type_of_Wood_Floor",
      type: "DROPDOWN",
      option: [
        "Nailed Wood Floor",
        "Glued Hardwood Floor",
        "Glued Parquet Floor",
        "Unknown Installation Type",
      ],
    },
    {
      question: "Notes For Wood Type Floor Covering",
      key: "Notes_For_Wood_Type_Floor_Covering",
      type: "TEXT",
    },
    {
      question: "Estimated Thickness of the tile floor",
      key: "Estimated_Thickness_of_the_tile_floor",
      type: "TEXT",
    },
    {
      question: "Is the existing tile coming loose in any areas?",
      key: "Is_the_existing_tile_coming_loose_in_any_areas",
      type: "BOOLEAN",
    },
    {
      question: "Description of multiple floor covering types",
      key: "Description_of_multiple_floor_covering_types",
      type: "TEXT",
    },
    {
      question: "Description of other floor covering types",
      key: "Description_of_other_floor_covering_types",
      type: "TEXT",
    },
    {
      question: "Description of other floor covering types",
      key: "Description_of_other_floor_covering_types",
      type: "TEXT",
    },
  ],
  [
    {
      question: "Below Subfloor",
      key: "Below_Subfloor",
      type: "DROPDOWN",
      option: [
        "Finished Basement",
        "Unfinished Basement",
        "Crawlspace",
        "Finished Living Space",
        "Nothing (slab)",
        "Apartment Building",
        "Other",
      ],
    },
    {
      question: "Are there any humidity concerns?",
      key: "Are_there_any_humidity_concerns",
      type: "BOOLEAN",
    },
    {
      question: "Is the unfinished floor finished?",
      key: "Is_the_unfinished_floor_finished",
      type: "BOOLEAN",
    },
    {
      question: "Other notes for the unfinished basement",
      key: "Other_notes_for_the_unfinished_basement",
      type: "TEXT",
    },
    {
      question: "Is the crawlspace floor finished",
      key: "Is_the_crawlspace_floor_finished",
      type: "BOOLEAN",
    },
    {
      question: "Is there proper insulation",
      key: "Is_there_proper_insulation",
      type: "BOOLEAN",
    },
    {
      question: "Other notes for the Crawlspace",
      key: "Other_notes_for_the_Crawlspace",
      type: "BOOLEAN",
    },
  ],
  [
    {
      question: "Radiant Heating",
      key: "Radiant_Heating",
      type: "BOOLEAN",
    },
    {
      question: "Is the system Electric or Water?",
      key: "Is_the_system_Electric_or_Water",
      type: "DROPDOWN",
      option: ["Electric Heating System", "Water Heating System", "Unknown"],
    },
  ],
  [
    {
      question: "Signs of Moisture",
      key: "Signs_of_Moisture",
      type: "BOOLEAN",
    },
    {
      question: "Description of any water or moisture issues identified",
      key: "Description_of_any_water_or_moisture_issues_identi",
      type: "BOOLEAN",
    },
  ],
  [
    {
      question: "Subfloor Detail Notes",
      key: "Field_Visit_Subfloor_Dtls_Notes",
      type: "TEXT",
    },
  ],
];
