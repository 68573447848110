import _ from "lodash";
import { setChecklist, setCustomerData } from "../redux/customer";
import { CHECKLIST_DEFAULT_COLORS, CHECKLIST_JUNCTION_MAP } from "./constants";

export const cloneData = (data) => {
  return JSON.parse(JSON.stringify(data));
};

const CL_NAME_WITH_DASH_IN_IMAGE = [
  "Refinishing Checklist",
  "Subfloor Detail",
  "Matching Installation",
];

export const getImages = (selectedChecklist, customerData, realtedFormKey) => {
  let clNameToCheck = selectedChecklist?.Name?.substring(
    customerData?.potential?.Deal_Name?.length
  )?.trim();

  if (CL_NAME_WITH_DASH_IN_IMAGE.includes(realtedFormKey)) {
    // Replace hyphens surrounded by spaces and then trim the result
    clNameToCheck = clNameToCheck?.replace(/ - /g, " ")?.trim();

    const clKeyToCompareWithRelatedForm = clNameToCheck
      ?.replace(/\d+$/, "")
      .trim();

    // Filter the images based on the formatted clNameToCheck
    return _.filter(
      customerData?.Field_Visit_Images,
      (item) =>
        item?.Related_To_Form === clKeyToCompareWithRelatedForm &&
        item?.Name?.includes(clNameToCheck)
    );
  } else {
    return _.filter(customerData?.Field_Visit_Images, (item) => {
      // Transition - 2 # Image-1; image name is like this, so split it and then remove substring and then compare it
      let nameToCheck = item?.Name?.split("#");
      nameToCheck = nameToCheck?.[0]?.trim();

      if (
        item?.Related_To_Form === realtedFormKey &&
        nameToCheck === clNameToCheck
      ) {
        return true;
      }
      return false;
    });
  }
};

export const getChecklistToChange = (clName) => {
  if (clName?.includes("Refinishing Checklist")) {
    return "FV_Refinishing_Checklists";
  } else if (clName?.includes("Existing Material")) {
    return "FV_Existing_Materials";
  } else if (clName?.includes("Subfloor Detail")) {
    return "FV_Subfloor_Details";
  } else if (clName?.includes("Molding")) {
    return "FV_Moldings";
  } else if (clName?.includes("Matching Refinishing")) {
    return "FV_Matching_Refinishings";
  } else if (clName?.includes("Matching Installation")) {
    return "FV_Matching_Installations";
  } else if (clName?.includes("Leveling")) {
    return "FV_Levelings";
  } else if (clName?.includes("Staircase")) {
    return "FV_Staircases";
  } else if (clName?.includes("Repair")) {
    return "FV_Repairs";
  } else if (clName?.includes("Transition")) {
    return "FV_Transitions";
  }
};

export const getQuestionAnswer = (cl, questionObj, event) => {
  let answer = null;
  if (questionObj.type === "BOOLEAN") {
    // if by default the question's answer is "none" then we are updating the new value as false
    if (typeof cl[questionObj.key] === "string") {
      answer = false;
    } else {
      answer = !cl[questionObj.key];
    }
  } else {
    answer = event?.target?.value || null;
  }
  return answer;
};

export const updateChecklists = (
  customerData,
  checklistToChange,
  selectedChecklist,
  question,
  answer,
  dispatch
) => {
  let updatedCurrentSelectedChecklist = {};
  const updatedChecklists = customerData[checklistToChange]?.map((cl) => {
    if (cl?.id === selectedChecklist?.id) {
      updatedCurrentSelectedChecklist = { ...cl, [question.key]: answer };
      return { ...cl, [question.key]: answer };
    }
    return cl;
  });

  customerData[checklistToChange] = updatedChecklists;

  dispatch(setChecklist(updatedCurrentSelectedChecklist));
  dispatch(setCustomerData(customerData));

  return updatedChecklists;
};

const updateDependentScopeAnswer = (updatedScope, answer, answerKey) => {
  if (answer === false) {
    if (answerKey === "Furniture") {
      updatedScope = {
        ...updatedScope,
        KASA_is_Moving: false,
        Appliance: false,
        Special_Items: false,
      };
    } else if (answerKey === "KASA_is_Moving") {
      updatedScope = {
        ...updatedScope,
        Appliance: false,
        Special_Items: false,
      };
    } else if (answerKey === "Refinishing") {
      updatedScope = {
        ...updatedScope,
        Matching_For_Refinishing: false,
      };
    } else if (answerKey === "Installation") {
      updatedScope = {
        ...updatedScope,
        Matching_For_Installation: false,
        Levelling_For_Installation: false,
      };
    }
  }

  return updatedScope;
};

export const updateScope = (
  customerData,
  scope,
  question,
  answer,
  dispatch
) => {
  const answerKey = question?.key;
  let updatedScope = { ...scope, [answerKey]: answer };

  /**
   * Update the answer of it's dependent scope, like if furniture is being out of scope, then kasa_is_moving, appliance and special items should be false
   */
  updatedScope = updateDependentScopeAnswer(updatedScope, answer, answerKey);

  customerData.fv_scope = updatedScope;

  dispatch(setCustomerData(customerData));
};

export const getChecklistJunction = (clKey, type) => {
  const item = CHECKLIST_JUNCTION_MAP[clKey];
  if (item) {
    return item[type] || [];
  }
  return [];
};

export const sortChecklistsBasedOnName = (checlists, customerData) => {
  if (!checlists) return [];

  const sortedChecklists = checlists?.sort((a, b) => {
    const aClName = a?.Name?.substring(
      customerData?.potential?.Deal_Name?.length
    );
    const bClName = b?.Name?.substring(
      customerData?.potential?.Deal_Name?.length
    );

    return aClName?.localeCompare(bClName);
  });

  return sortedChecklists;
};

export const isChecklistInScope = (key, scope) => {
  switch (key) {
    case "FV_Refinishing_Checklists":
      if (scope?.Refinishing) return true;
      break;
    case "FV_Existing_Materials":
      if (scope?.Refinishing) return true;
      break;
    case "FV_Subfloor_Details":
      if (scope?.Installation) return true;
      break;
    case "FV_Matching_Refinishings":
      if (scope?.Refinishing && scope?.Matching_For_Refinishing) return true;
      break;
    case "FV_Matching_Installations":
      if (scope?.Installation && scope?.Matching_For_Installation) return true;
      break;
    case "FV_Moldings":
      return true;
    case "FV_Levelings":
      if (scope?.Installation && scope?.Levelling_For_Installation) return true;
      break;
    case "FV_Staircases":
      if (scope?.Staircase) return true;
      break;
    case "FV_Repairs":
      if (scope?.Repair) return true;
      break;
    case "FV_Transitions":
      if (scope?.Transition) return true;
      break;
    default:
      return false;
  }
};

export const generateRandomColor = (existingColors) => {
  const characters = "0123456789ABCDEF";
  let color;
  let isUnique = false;

  // Function to generate a random color
  const getRandomColor = () => {
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += characters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Keep generating random colors until a unique color is found
  while (!isUnique) {
    color = getRandomColor();
    isUnique = !existingColors.includes(color);
  }

  return color;
};

export const getChecklistColor = (clonedCustomerData, clName) => {
  const checklistKey = getChecklistToChange(clName);

  /**
   * Get used colors for checklists, and based on those, we can get available colors, and if not available then generate new color
   */
  const usedColors = clonedCustomerData?.[checklistKey]?.map((cl) => cl.Color);
  const currentChecklistsLength = clonedCustomerData?.[checklistKey]?.length;

  let newChecklistColor = null;
  if (currentChecklistsLength < 5) {
    const availableColors = CHECKLIST_DEFAULT_COLORS.filter(
      (color) => !usedColors.includes(color)
    );
    newChecklistColor = availableColors[0];
  } else {
    newChecklistColor = generateRandomColor(usedColors);
  }

  return newChecklistColor;
};

export const updteCustomerDataWithNewChecklist = (
  customerData,
  newChecklist,
  clKey,
  dispatch,
  customerDataToUpdate
) => {
  let clonedOldCustomerData = JSON.parse(JSON.stringify(customerData));
  const updatedChecklists = [...clonedOldCustomerData?.[clKey], newChecklist];
  clonedOldCustomerData[clKey] = updatedChecklists;
  dispatch(customerDataToUpdate(clonedOldCustomerData));
};
