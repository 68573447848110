import React from "react";
import SidebarContent from "./sidebar-content";

const Sidebar = (props) => {
  const { className } = props;
  return (
    <div
      className={`h-full w-[310px] z-30 flex-none border-r-[1px] border-gray-300 bg-gray-100 ${className}`}
    >
      <div
        className="my-2 px-4 !overflow-y-auto"
        style={{ height: "calc(100% - 10px)" }}
      >
        <SidebarContent />
      </div>
    </div>
  );
};

export default Sidebar;
