import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import _ from "lodash";
import RoomButton from "./room-button";
import { cloneData, getChecklistJunction } from "../../utils/common-utils";
import { setIsLoading } from "../../redux/customer";
import { FloorUtil } from "../../utils/floor-utils";
import MuiSnackbar from "../ui/mui-snackbar";
import { CreateRecord } from "../../utils/create-record";
import {
  deleteRecordFromZoho,
  updateFVRecordInZoho,
} from "../../services/customer.service";
import {
  FLOOR_CLICK_SHOULD_NOT_WORK_FOR,
  FROM_TO_WHITHIN_BTN_SHOW,
} from "../../utils/constants";
import DragButton from "../ui/drag-button";
import { RoomBgColor } from "../../utils/room-bg-color-util";

const Floors = (props) => {
  const {
    customerData,
    roomsWithinSelectedChecklist,
    updateChecklistInRoom,
    selectedChecklist,
    floorWiseRooms,
    clKey,
    poId,
    fetchCustomerData,
  } = props;

  const dispatch = useDispatch();
  const alertRef = useRef(null);

  const getBgColorForRoom = useCallback(
    (room) => {
      const bgColorInstance = new RoomBgColor();

      if (clKey === "FV_Transitions") {
        return bgColorInstance.getBgColorForRoomInTransition(
          room,
          selectedChecklist
        );
      } else if (clKey === "FV_Repairs") {
        return bgColorInstance.getBgColorForRoomInRepair(
          room,
          selectedChecklist
        );
      } else if (clKey === "FV_Levelings") {
        return bgColorInstance.getBgColorForRoomInLeveling(
          room,
          customerData,
          selectedChecklist
        );
      } else {
        return bgColorInstance.getBgColorForRoom(
          room,
          roomsWithinSelectedChecklist
        );
      }
    },
    [roomsWithinSelectedChecklist, selectedChecklist, clKey, customerData]
  );

  const updateChecklistInFloor = useCallback(
    async (floorName) => {
      if (!floorName || !clKey) return null;
      if (FLOOR_CLICK_SHOULD_NOT_WORK_FOR.includes(clKey)) return null;

      dispatch(setIsLoading(true));

      let clonedCustomerData = cloneData(customerData);
      const floorInstance = new FloorUtil(clonedCustomerData);
      const roomIds = floorInstance.getRoomIdsFromFloor(floorName);
      const [checklistJunctionKey, junctionInsiderKeyToCheck] =
        getChecklistJunction(clKey, "junction");

      const moduleName = checklistJunctionKey;
      const selectedChecklistId = selectedChecklist.id;

      const roomChecklists = _.groupBy(
        clonedCustomerData[checklistJunctionKey],
        "Field_Visit_Room.id"
      );

      const allRoomsHaveCurrentCL = _.every(roomIds, (roomId) =>
        _.some(
          roomChecklists[roomId],
          (junction) =>
            junction[junctionInsiderKeyToCheck]?.id === selectedChecklistId
        )
      );

      const promises = roomIds.map(async (roomId) => {
        const checklistsInRoom = roomChecklists[roomId] || [];
        const currentCLExistInRoom = checklistsInRoom.find(
          (junction) =>
            junction[junctionInsiderKeyToCheck]?.id === selectedChecklistId
        );

        if (allRoomsHaveCurrentCL && currentCLExistInRoom) {
          return deleteRecordFromZoho({
            module_name: moduleName,
            record_id: currentCLExistInRoom.id,
          });
        }

        if (!currentCLExistInRoom) {
          const clExistInRoom = checklistsInRoom[0];
          if (clExistInRoom) {
            const updateRecordBody = {
              ...clExistInRoom,
              [junctionInsiderKeyToCheck]: {
                name: selectedChecklist.Name || "",
                id: selectedChecklistId,
              },
            };
            return updateFVRecordInZoho(poId, {
              module_name: moduleName,
              record_obj: updateRecordBody,
            });
          } else {
            const room = customerData.FV_Rooms?.find((r) => r.id === roomId);
            const recordInstance = new CreateRecord({
              selectedChecklist,
              room,
              customerData,
              junctionInsiderKeyToCheck,
            });
            const newRecord = recordInstance.getNewRecord();
            return updateFVRecordInZoho(poId, {
              module_name: moduleName,
              record_obj: newRecord,
            });
          }
        }
      });

      await Promise.all(promises);
      await fetchCustomerData();
      dispatch(setIsLoading(false));
    },
    [customerData, clKey, selectedChecklist]
  );

  const getStairCaseColor = useCallback(
    (floorName) => {
      if (clKey == "FV_Staircases") {
        const bgColorInstance = new RoomBgColor();
        return bgColorInstance.getBgColorForRoomInStaircase(
          floorName,
          selectedChecklist
        );
      }
    },
    [selectedChecklist, clKey]
  );

  return (
    <>
      {FROM_TO_WHITHIN_BTN_SHOW.includes(clKey) && (
        <div className="flex justify-center items-center gap-2">
          <DragButton type="From" clKey={clKey} />
          {clKey === "FV_Transitions" && (
            <DragButton type="Within" clKey={clKey} />
          )}
          <DragButton type="To" clKey={clKey} />
        </div>
      )}
      <Grid container justifyContent={"center"} spacing={1}>
        {floorWiseRooms?.map((floor, idx) => {
          return (
            <Grid item xs={3} sm={2} justifyContent={"center"}>
              <RoomButton
                className="max-w-[200px] w-full border-gray-400 font-semibold mb-2"
                key={`${floor?.floorName} + ${idx}`}
                buttonName={floor?.floorName || ""}
                onClick={() => updateChecklistInFloor(floor?.floorName)}
                clKey={clKey}
                ref={alertRef}
                poId={poId}
                style={getStairCaseColor(floor?.floorName)}
                type="floor"
              />
              {clKey !== "FV_Staircases" && (
                <div className="space-y-2 w-full">
                  {floor?.rooms?.map((room, index) => {
                    return (
                      <div>
                        <RoomButton
                          className="max-w-[200px] w-full"
                          key={room?.id || index}
                          buttonName={room?.Display_Name || ""}
                          onClick={() => updateChecklistInRoom(room)}
                          style={getBgColorForRoom(room)}
                          clKey={clKey}
                          ref={alertRef}
                          poId={poId}
                          room={room}
                          type="room"
                        />
                        <div className="space-y-2">
                          {room?.subRooms?.map((subRoom) => {
                            return (
                              <div className="flex justify-start items-center max-w-[195px] ml-1">
                                <img
                                  src="/icons/subroom-arrow.png"
                                  className="w-[15px] h-[17px]"
                                />
                                <RoomButton
                                  className="max-w-[200px] w-full ml-1 mt-2"
                                  key={subRoom?.id || index}
                                  buttonName={subRoom?.Display_Name || ""}
                                  onClick={() => updateChecklistInRoom(subRoom)}
                                  style={getBgColorForRoom(subRoom)}
                                  clKey={clKey}
                                  ref={alertRef}
                                  poId={poId}
                                  room={subRoom}
                                  type="room"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Grid>
          );
        })}
        <MuiSnackbar ref={alertRef} />
      </Grid>
    </>
  );
};

export default Floors;
