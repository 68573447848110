import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Backdrop, LinearProgress, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import _ from "lodash";
import RoomImages from "./room-images";
import ChecklistImages from "./checklist-images";
import { getImages } from "../../utils/common-utils";
import NoContentContainer from "../../components/ui/no-content";
import CustomDragLayer from "../../components/ui/CustomDragLayer";
import BackdropLinearProgress from "../../components/ui/backdrop-linear-progress";

const Images = () => {
  const selectedRoom = useSelector(
    (state) => state.customerReducer.selectedRoom
  );
  const selectedChecklist = useSelector(
    (state) => state.customerReducer.selectedChecklist
  );
  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );
  const uploading = useSelector((state) => state.customerReducer.uploading);
  const [otherClImages, setOtherClImages] = useState([]);

  const roomImages = useMemo(() => {
    return _.filter(
      customerData?.Field_Visit_Images,
      (item) =>
        item?.Related_To_Form === "Rooms" &&
        item?.Field_Visit_Room?.id === selectedRoom?.id
    );
  }, [customerData, selectedRoom]);

  const clImages = useMemo(() => {
    /**
     * All images that are comes without ' - ' includes in Name key
     */

    // Extract and format clNameToCheck
    let clNameToCheck = selectedChecklist?.Name?.substring(
      customerData?.potential?.Deal_Name?.length
    );

    // Replace hyphens surrounded by spaces and then trim the result
    clNameToCheck = clNameToCheck?.replace(/ - /g, " ")?.trim();

    const clKeyToCompareWithRelatedForm = clNameToCheck
      ?.replace(/\d+$/, "")
      .trim();

    // Filter the images based on the formatted clNameToCheck
    return _.filter(
      customerData?.Field_Visit_Images,
      (item) =>
        item?.Related_To_Form === clKeyToCompareWithRelatedForm &&
        item?.Name?.includes(clNameToCheck)
    );
  }, [customerData, selectedChecklist]);

  const getImagesToRender = useCallback(() => {
    let trnName = selectedChecklist?.Name?.substring(
      customerData?.potential?.Deal_Name?.length
    )?.trim();

    let images = [];
    /**
     * All images that are comes with ' - ' includes in Name key
     */
    if (trnName?.includes("Transition")) {
      images = getImages(selectedChecklist, customerData, "Transition");
    } else if (trnName?.includes("Staircase")) {
      images = getImages(selectedChecklist, customerData, "Staircase");
    } else if (trnName?.includes("Leveling")) {
      images = getImages(selectedChecklist, customerData, "Leveling");
    } else if (trnName?.includes("Molding")) {
      images = getImages(selectedChecklist, customerData, "Molding");
    } else if (trnName?.includes("Repair")) {
      images = getImages(selectedChecklist, customerData, "Repair");
    } else if (trnName?.includes("Existing Material")) {
      images = getImages(selectedChecklist, customerData, "Existing Material");
    } else if (trnName?.includes("Matching Refinishing")) {
      images = getImages(
        selectedChecklist,
        customerData,
        "Matching Refinishing"
      );
    }

    setOtherClImages(images);
  }, [customerData, selectedChecklist]);

  useEffect(() => {
    getImagesToRender();
  }, [getImagesToRender]);

  return (
    <Stack sx={{ m: 3 }}>
      {selectedRoom && (
        <RoomImages roomImages={roomImages} selectedRoom={selectedRoom} />
      )}

      {selectedChecklist && (
        <ChecklistImages clImages={clImages} otherClImages={otherClImages} />
      )}

      {!selectedRoom && !selectedChecklist && (
        <NoContentContainer>Please select room or checklist</NoContentContainer>
      )}
      {uploading && <BackdropLinearProgress />}
      <CustomDragLayer />
    </Stack>
  );
};

export default Images;
