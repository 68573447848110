import React, { useCallback, useMemo, useRef } from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ChecklistForm from "./checklist-form";
import BooleanField from "../../../components/question-fields/boolean";
import NoteField from "../../../components/question-fields/note";
import DropdownField from "../../../components/question-fields/dropdown";
import SliderField from "../../../components/question-fields/slider";
import {
  cloneData,
  getChecklistJunction,
  getChecklistToChange,
  getQuestionAnswer,
  updateChecklists,
} from "../../../utils/common-utils";
import MotionButton from "../../../components/ui/motion-button";
import { Constant, FROM_TO_WHITHIN_BTN_SHOW } from "../../../utils/constants";
import { updateFVRecordInZoho } from "../../../services/customer.service";
import MuiSnackbar from "../../../components/ui/mui-snackbar";
import {
  setChecklist,
  setCustomerData,
  setIsLoading,
  setOldCustomerData,
} from "../../../redux/customer";
import NoContentContainer from "../../../components/ui/no-content";
import { CreateRecord } from "../../../utils/create-record";
import { FloorUtil } from "../../../utils/floor-utils";
import { ChecklistApiUtil } from "../../../utils/checklist-api-util";

const Checklists = (props) => {
  const { customer_po_id, fetchCustomerData } = props;

  const selectedChecklist = useSelector(
    (state) => state.customerReducer.selectedChecklist
  );
  const customerData = useSelector(
    (state) => state.customerReducer.customerData
  );
  const oldCustomerData = useSelector(
    (state) => state.customerReducer.oldCustomerData
  );

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = React.useState(false);
  const alertRef = useRef(null);

  /**
   * Get checklist key based on selected checklist's Name key
   */
  const clKey = useMemo(() => {
    return getChecklistToChange(selectedChecklist?.Name || "");
  }, [selectedChecklist]);

  const floorWiseRooms = useMemo(() => {
    let floorInstance = new FloorUtil(customerData);
    // floorInstance.getFloorWiseRoomsWithSubRooms();
    return floorInstance.getFloorWiseRoomsWithSubRooms();
  }, [customerData]);

  const isChecklistChanged = useMemo(() => {
    const clonedOldCustomerData = cloneData(oldCustomerData);
    const oldChecklist = clonedOldCustomerData?.[clKey]?.find(
      (cl) => cl.id === selectedChecklist.id
    );

    const isDataChanged = !_.isEqual(selectedChecklist, oldChecklist);
    return isDataChanged;
  }, [clKey, selectedChecklist, oldCustomerData]);

  const updateAnswer = useCallback(
    (question, answer) => {
      const clonedCustomerData = JSON.parse(JSON.stringify(customerData));
      updateChecklists(
        clonedCustomerData,
        clKey,
        selectedChecklist,
        question,
        answer,
        dispatch
      );
    },
    [customerData, selectedChecklist]
  );

  const renderQuestions = useCallback(
    (queObj) => {
      switch (queObj.type) {
        case "BOOLEAN":
          return (
            <BooleanField
              question={queObj?.question}
              value={selectedChecklist?.[queObj.key]}
              handleClickBoolean={() => {
                const answer = getQuestionAnswer(
                  selectedChecklist,
                  queObj,
                  null
                );
                updateAnswer(queObj, answer);
              }}
            />
          );
        case "TEXT":
          return (
            <NoteField
              question={queObj?.question}
              value={selectedChecklist?.[queObj.key] || ""}
              handleNoteBlur={(event) => {
                const answer = getQuestionAnswer(
                  selectedChecklist,
                  queObj,
                  event
                );
                updateAnswer(queObj, answer);
              }}
              id={queObj?.key}
              name={queObj?.key}
              type={queObj?.fieldType || "text"}
            />
          );
        case "DROPDOWN":
          return (
            <DropdownField
              question={queObj?.question}
              value={selectedChecklist?.[queObj.key] || ""}
              options={queObj?.option}
              handleChangeDropdown={(event) => {
                const answer = getQuestionAnswer(
                  selectedChecklist,
                  queObj,
                  event
                );
                updateAnswer(queObj, answer);
              }}
            />
          );
        case "RANGE":
          return (
            <SliderField
              question={queObj?.question}
              value={selectedChecklist?.[queObj.key] || 0}
              handleBlurSlider={(value) => {
                updateAnswer(queObj, Number(value));
              }}
            />
          );
        default:
          return null;
      }
    },
    [customerData, selectedChecklist]
  );

  const handleSubmitData = useCallback(async () => {
    setSubmitting(true);
    const updateRecordBody = {
      module_name: clKey,
      record_obj: selectedChecklist,
    };

    const recordUpdateResponse = await updateFVRecordInZoho(
      customer_po_id,
      updateRecordBody
    );

    if (recordUpdateResponse.data?.status) {
      alertRef.current.openSnackbar(true, "Record udpated!", "success");

      let clonedOldCustomerData = JSON.parse(JSON.stringify(oldCustomerData));
      const oldChecklistIndex = clonedOldCustomerData?.[clKey]?.findIndex(
        (cl) => cl.id === selectedChecklist.id
      );
      clonedOldCustomerData[clKey][oldChecklistIndex] = selectedChecklist;

      dispatch(setOldCustomerData(clonedOldCustomerData));
    } else {
      alertRef.current.openSnackbar(
        true,
        recordUpdateResponse.data?.message || "Something went wrong!",
        "error"
      );
    }
    setSubmitting(false);
  }, [clKey, selectedChecklist]);

  const shouldButtonDisabled = useMemo(() => {
    return submitting || !isChecklistChanged;
  }, [submitting, isChecklistChanged]);

  const updateChecklistInRoom = useCallback(
    async (room) => {
      if (!room || !clKey) return null;
      /**
       * click should not work for staircase and transition, there is a drop event for this operation
       */
      if (FROM_TO_WHITHIN_BTN_SHOW.includes(clKey)) return;

      let clonedCustomerData = cloneData(customerData);

      if (clKey === "FV_Repairs") {
        let curRepair = clonedCustomerData[clKey]?.find(
          (r) => r.id === selectedChecklist.id
        );

        if (curRepair.Within_Room && curRepair.Within_Room.id == room.id)
          return;

        dispatch(setIsLoading(true));
        curRepair.Within_Room = {
          name: room.Name,
          id: room.id,
        };

        const updateRecordInstance = new ChecklistApiUtil(clKey);
        const updatedRepair = await updateRecordInstance.addNewRecord(
          customer_po_id,
          curRepair,
          alertRef.current,
          fetchCustomerData
        );

        if (updatedRepair) {
          clonedCustomerData[clKey] = clonedCustomerData[clKey].map((r) => {
            if (r.id === selectedChecklist.id) {
              return updatedRepair;
            }
            return r;
          });

          dispatch(setChecklist(updatedRepair));
          dispatch(setCustomerData(clonedCustomerData));
          dispatch(setOldCustomerData(clonedCustomerData));
        }
      } else {
        dispatch(setIsLoading(true));
        const [checklistJunctionKey, junctionInsiderKeyToCheck] =
          getChecklistJunction(clKey, "junction");

        const roomInCurrentCL = clonedCustomerData[checklistJunctionKey]?.find(
          (junction) =>
            junction[junctionInsiderKeyToCheck]?.id === selectedChecklist.id &&
            junction?.Field_Visit_Room?.id === room?.id
        );

        /**
         * initialize junction module, if not exist
         */
        if (!clonedCustomerData[checklistJunctionKey]) {
          clonedCustomerData[checklistJunctionKey] = [];
        }

        const updateRecordInstance = new ChecklistApiUtil(checklistJunctionKey);

        if (roomInCurrentCL) {
          const deleteRecordId = roomInCurrentCL?.id;
          await updateRecordInstance.deleteRecord(
            deleteRecordId,
            alertRef.current,
            fetchCustomerData
          );

          ChecklistApiUtil.deleteJunctionModuleFromCustomerData(
            customerData,
            checklistJunctionKey,
            deleteRecordId,
            dispatch
          );
        } else {
          const roomInOtherCL = clonedCustomerData[checklistJunctionKey]?.find(
            (junction) =>
              junction[junctionInsiderKeyToCheck]?.id !==
                selectedChecklist.id &&
              junction?.Field_Visit_Room?.id === room?.id
          );

          let newRecordBody = null;
          if (roomInOtherCL) {
            newRecordBody = {
              ...roomInOtherCL,
              [junctionInsiderKeyToCheck]: {
                name: selectedChecklist.Name || "",
                id: selectedChecklist.id,
              },
            };
          } else {
            const recordInstance = new CreateRecord({
              selectedChecklist,
              room,
              customerData,
              junctionInsiderKeyToCheck,
            });

            newRecordBody = recordInstance.getNewRecord();
          }

          if (newRecordBody) {
            const newChecklistJunction =
              await updateRecordInstance.addNewRecord(
                customer_po_id,
                newRecordBody,
                alertRef.current,
                fetchCustomerData
              );

            if (newChecklistJunction)
              ChecklistApiUtil.updateCustomerDataWithJunctionModule(
                customerData,
                checklistJunctionKey,
                newChecklistJunction,
                dispatch
              );
          }
        }
      }
      dispatch(setIsLoading(false));
    },
    [customerData, clKey, setCustomerData, fetchCustomerData, selectedChecklist]
  );

  return (
    <Stack sx={{ m: 3 }}>
      {selectedChecklist ? (
        <>
          <div className={`flex justify-end items-center mb-2`}>
            <MotionButton
              className={`text-white rounded-md max-w-7 ${
                shouldButtonDisabled ? "bg-gray-200" : Constant.BG_PRIMARY
              } ${submitting && "cursor-progress"}`}
              disabled={shouldButtonDisabled}
              onClick={handleSubmitData}
            >
              SUBMIT DATA
            </MotionButton>
          </div>
          <ChecklistForm
            selectedChecklist={selectedChecklist}
            renderQuestions={renderQuestions}
            checklistItems={getChecklistJunction(clKey, "checklist")}
            clKey={clKey}
            updateChecklistInRoom={updateChecklistInRoom}
            floorWiseRooms={floorWiseRooms}
            poId={customer_po_id}
            fetchCustomerData={fetchCustomerData}
          />
        </>
      ) : (
        <NoContentContainer>
          Please select any checklist to see the questions
        </NoContentContainer>
      )}
      <MuiSnackbar ref={alertRef} />
    </Stack>
  );
};

export default Checklists;
