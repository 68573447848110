import React from 'react';
import { useDragLayer } from 'react-dnd';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

const getItemStyles = (currentOffset) => {
    if (!currentOffset) {
        return {
            display: 'none',
        };
    }
    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;

    return {
        transform,
        WebkitTransform: transform,
    };
};

const CustomDragLayer = () => {
    const {
        itemType,
        isDragging,
        item,
        currentOffset,
    } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    if (!isDragging) {
        return null;
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(currentOffset)}>
                {itemType === 'ROOM_IMAGE' && (
                    <img
                        src={item.imgUrl}
                        alt="Room Preview"
                        style={{ width: 100, height: 80, objectFit: 'cover', borderRadius: '10px' }}
                    />
                )}
            </div>
        </div>
    );
};

export default CustomDragLayer;
