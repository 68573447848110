import React from "react";
import { motion } from "framer-motion";

const RemoveButton = (props) => {
  const { ...rest } = props;
  return (
    <motion.button whileTap={{ scale: 0.95 }} {...rest}>
      <div className="flex items-center justify-center p-2  ">
        <img src="/icons/delete-icon.svg" className="w-5 h-5" />
      </div>
      {/* <div
        className={`flex items-center justify-center p-3 rounded-full w-6 h-6 bg-red-600`}
      >
        <span className="text-white text-lg">-</span>
      </div> */}
    </motion.button>
  );
};

export default RemoveButton;
