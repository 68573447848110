import axios from 'axios'
import { getAuth } from './identity.service'

export const postWithOutAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    axios
      .post(url, entity)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data })
        }
      })
      .catch((ex) => {
        resolve({ status: false, data: ex.response.data })
      })
  })
}

export const getWithOutAuth = (url) => {
  return new Promise((resolve, _reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data })
        }
      })
      .catch((ex) => {
        resolve({ status: false, error: ex.message })
      })
  })
}

export const postWithAuthImg = (url, entity, setProgress) => {
  return new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
      "content-type": "multipart/form-data",
      "x-access-token": auth.token,
    };

    axios
      .post(url, entity, {
        headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(progress);
        },
      })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });
};

export const postWithAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
      "content-type": "application/json",
      "x-access-token": auth.token,
    };

    axios
      .post(url, entity, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, data: ex.response.data });
      });
  });
};