export const MATCHING_INS = [
  [
    {
      question: "Type of Floor",
      key: "Type_of_Floor",
      type: "DROPDOWN",
      option: [
        "Prefinished Solid",
        "Prefinished Engineered",
        "Unfinished Solid",
        "Unfinished Engineered",
        "Laminate",
        "Vinyl",
      ],
    },
    {
      question: "Type of Matching",
      key: "Type_of_Matching",
      type: "DROPDOWN",
      option: ["Product", "Specifications", "Look"],
    },
    {
      question: "Are we Refinishing?",
      key: "Are_we_Refinishing",
      type: "DROPDOWN",
      option: ["Yes", "No"],
    },
    {
      question: "How is the space connected to the area being refinished?",
      key: "How_space_connected_to_the_area_being_refinshed",
      type: "DROPDOWN",
      option: [
        "Adjacent",
        "Near",
        "Different Levels",
        "Different Areas of the home",
      ],
    },
    {
      question: "Does customer have material specs?",
      key: "Does_customer_have_material_specs",
      type: "BOOLEAN",
    },
    {
      question: "FV Matching Installation Notes",
      key: "FV_Matching_Installation_Notes",
      type: "BOOLEAN",
    },
    {
      question: "Type",
      key: "Type",
      type: "DROPDOWN",
      option: [
        "Site Finished Solid",
        "Site Finished Engineered",
        "Prefinished Solid",
        "Prefinished Engineered",
        "Unknown",
        "New Installation",
      ],
    },
    {
      question: "Species",
      key: "Species",
      type: "DROPDOWN",
      option: [
        "White Oak",
        "Red Oak",
        "Southern Yellow Pine",
        "Heart Pine",
        "Eastern White Pine",
        "Pine - Unknown",
        "Maple",
        "Walnut",
        "Brazilian Cherry",
        "Ash",
        "Douglas Fir",
        "Oak - Unknown Type",
        "Other",
      ],
    },
    {
      question: "Layout",
      key: "Layout_of_Install",
      type: "DROPDOWN",
      option: ["Straight", "Diagonal", "Herringbone", "Chevron", "Other"],
    },
    {
      question: "Width",
      key: "Width",
      type: "TEXT",
      fieldType: "number",
    },
    {
      question: "Thickness",
      key: "Thickness",
      type: "DROPDOWN",
      option: ['5/16"', '3/8"', '1/2"', '5/8"', '9/16"', '3/4"', '1"', "Other"],
    },
    {
      question: "Installation",
      key: "Installation",
      type: "DROPDOWN",
      option: ["Nailed", "Glued", "Floated", "Unknown"],
    },
    {
      question: "Sawn Type",
      key: "Sawn_Type",
      type: "DROPDOWN",
      option: [
        "Plain Sawn",
        "Rift and Quartered",
        "Rift",
        "Quartered",
        "Live Sawn",
        "Rotary Peeled",
      ],
    },
  ],
];
